import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "../components/Sidebar";
import { useEffect, useState } from "react";
import { Header } from "../components/Header";
import { api } from "../services/api";
import { MobileSidebar } from "../components/Sidebar/MobileSidebar";
import { UserProvider, useUser } from "./profile/context/user-context";

function MainApp() {
  const { userInfo } = useUser() as any;
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className="flex-1 flex">
      {!collapsed && <Sidebar user={userInfo} />}
      {collapsed && (
        <MobileSidebar
          user={userInfo}
          onClose={() => setCollapsed((prev) => !prev)}
        />
      )}
      <div className="flex-1 max-h-screen overflow-auto max-w-[1440px] mx-auto">
        <Outlet />
      </div>
    </div>
  );
}

export function Main() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // TODO: remove token & user from localStorage when logging out
  useEffect(() => {
    const token = localStorage.getItem("token");
    const userData = JSON.parse(localStorage.getItem("user") || "{}");
    console.log(token, userData);
    if (!token || !userData._id) {
      window.location.href = "/login";
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
      if (location.pathname === "/") {
        navigate("/dashboard");
      }
    }
  }, []);

  if (!isLoggedIn) return null;

  return (
    <UserProvider>
      <MainApp />
    </UserProvider>
  );
}
