import { PostImageWithSelector } from "./PostImageWithSelector";

export function ImageView({
  data,
  postToDelete,
  setPostToDelete,
  onView,
}: {
  data: any[];
  postToDelete: any[];
  setPostToDelete?: any;
  onView: any;
}) {
  const handleSelect = (post: any) => {
    if (postToDelete.find((i) => i._id === post._id)) {
      setPostToDelete(postToDelete.filter((i) => i._id !== post._id));
    } else {
      setPostToDelete([...postToDelete, post]);
    }
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-wrap gap-1">
        {data.map((item) => (
          <PostImageWithSelector
            onClick={() => onView(item)}
            post={item}
            key={item._id}
            selected={postToDelete.find((i) => i._id === item._id)}
          />
        ))}
      </div>
    </div>
  );
}
