import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/Logo.svg";

export function Header() {
  return (
    <div className="px-10 py-4 bg-white justify-between items-center inline-flex">
      <div className="w-24 h-7 justify-center items-center flex">
        <Logo />
      </div>
      <div className="justify-start items-start gap-3 flex">
        {/* <Link to="/login" className="px-4 py-2 bg-white rounded-md border border-primary justify-center items-center gap-2.5 flex">
          <div className="text-black text-sm font-medium leading-normal">
            Sign in
          </div>
        </Link> */}
        {/* <Link to="/register" className="px-4 py-2 bg-primary rounded-md justify-center items-center gap-2.5 flex">
          <div className="text-black text-sm font-medium leading-normal">
            Sign up
          </div>
        </Link> */}
      </div>
    </div>
  );
}
