import { Link } from "react-router-dom";
import { Button } from "../../../../../components/Button";
import { ReactComponent as PostIcon } from "../../../../../assets/post.svg";
import { CreatePostForm } from "../../schedule/components/CreatePostModal";
import { useState } from "react";
import { getUser } from "../../../../../services/utils";
import { api } from "../../../../../services/api";

export function PostView({
  posts,
  refresh,
  userProfile,
}: {
  posts: any[];
  refresh: () => void;
  userProfile: any;
}) {
  const [postDialog, setPostDialog] = useState(false);
  const [postForm, setPostForm] = useState<any>({});
  const [saving, setSaving] = useState(false);
  const user = getUser();

  const postGroup = posts.reduce((acc, item, index) => {
    if (index % 3 === 0) {
      acc.push([item]);
    } else {
      acc[acc.length - 1].push(item);
    }
    return acc;
  }, [] as any[][]);

  const fetchMedia = async (userId: string, refreshOnUpload?: boolean) => {
    console.log("fetching media");
    if (refreshOnUpload) {
      refresh();
    }
  };

  const regeneratePost = (form) => {
    api.updatePost(form._id, { ...form }).then((res) => {
      console.log(res);
      // setPostForm(res.data);
      refresh();
    });
  };

  const handleSchedule = (data: any) => {
    console.log(data);
    // update
    const object = {
      creationMode: "MANUAL",
      userId: user._id,
      type: data.type,
      imageUrl: data.imageUrl,
      tags: data.tags,
      location: data.location,
      caption: data.caption,
      scheduleDateTime: data.scheduleDateTime,
    };
    setSaving(true);
    api.createPost(object).then(async (res) => {
      console.log(res);
      await fetchMedia(user._id);
      setPostDialog(false);
      setSaving(false);
    });
    // api.schedulePost(user._id, postForm._id, data).then((res) => {
    //   console.log(res);
    //   setPostDialog(false);
    // });
  };

  const handleViewPost = (post: any) => {
    console.log(post);
    setPostDialog(true);
    setPostForm(post);
  };

  console.log(postGroup);

  return (
    <div className="mx-1.5">
      <div className="flex flex-col items-center gap-4 border-t border-[#DBDBDB] mt-11">
        <div className="gap-12 flex">
          <div className="relative border-t border-t-black border-t-[2px] pt-4 flex items-center gap-2">
            <PostIcon />
            <div className="text-black text-xs font-normal tracking-wide">
              POSTS
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 flex flex-wrap md:flex-nowrap md:flex-col gap-4 lg:gap-6 justify-center mb-6">
        {/* {
            posts.map((item) => (
                <div key={item._id} className="w-[250px] h-[250px] bg-black/10 relative">
                  <img src={item?.imageUrl} alt="post" className="w-full h-full object-cover z-[9999] absolute" style={{backdropFilter: 'blur(82px)'}} />
                </div>
            ))
        } */}
        {postGroup.map((group, index) => (
          <div key={index} className="flex gap-4 lg:gap-6 items-center w-full">
            {group.map((item) => (
              <div
                onClick={() => handleViewPost(item)}
                key={item._id}
                className="flex-1 h-auto max-h-[335px] bg-black/10 relative aspect-square max-w-[335px]"
              >
                <img
                  src={item?.imageUrl}
                  alt="post"
                  className="w-full h-full object-cover"
                  style={{ backdropFilter: "blur(82px)" }}
                />
                {/* <div style={{backgroundImage: `url(${item?.imageUrl})`}} className="absolute top-0 bottom-0 left-0 right-0 opacity-80 bg-cover" /> */}
              </div>
            ))}
          </div>
        ))}
      </div>
      {/* <div className="flex justify-center">
        <Link to="/schedule">
            <Button variant="outline" className="!py-2">Find your Schedule</Button>
        </Link>
      </div> */}

      <CreatePostForm
        open={postDialog}
        onClose={() => {
          console.log("close");
          setPostForm({});
          setPostDialog(false);
        }}
        userProfile={userProfile}
        postForm={postForm}
        setPostForm={setPostForm}
        onSchedule={handleSchedule}
        regeneratePost={regeneratePost}
        onRefresh={() => {
          setPostForm({});
          setPostDialog(false);
          fetchMedia(user._id);
        }}
        saving={saving}
      />
    </div>
  );
}
