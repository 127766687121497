import { Routes, Route } from "react-router-dom";
import { EditProfile } from "./profile";
import { Main } from "./main";
import { Login } from "./auth";
import { ConfirmEmail } from "./auth/ConfirmEmail";
import { ForgotPassword } from "./auth/ForgotPassword";
import { ResetPassword } from "./auth/ResetPassword";
import { Payment } from "./auth/Payment";
import { ProfileWrapper } from "./profile/ProfileWrapper";
import { Dashboard } from "./dashboard/Dashboard";
import { List } from "./users/List/List";
import { View } from "./users/View/View";

const RoutesList = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/confirm-email" element={<ConfirmEmail />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/" element={<Main />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="users" element={<List />} />
        <Route path="users/:id" element={<View />} />
      </Route>
    </Routes>
  );
};
export default RoutesList;
