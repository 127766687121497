import { Layout } from "./components/Layout";
import { Button } from "../../components/Button";
import { TextInput } from "../../components/TextInput";
import { Link } from "react-router-dom";
import React from "react";
import { api } from "../../services/api";
import { setToken, setUser } from "../../services/utils";

export function ForgotPassword() {
  const [showOnboarding, setShowOnboarding] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [isPasswordSame, setIsPasswordSame] = React.useState<string>("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // get the form data
    const formData = new FormData(e.currentTarget);
    // convert the form data to an object
    const data = Object.fromEntries(formData);
    // log the results
    console.log(data);
    setLoading(true);
    const response = await api.signup({
      email: data.email,
      password: data.password,
    });
    console.log(response);
    if (response.accessToken) {
      setToken(response.accessToken);
      setUser({ ...response });
      setShowOnboarding(true);
    }
    setLoading(false);
  };
  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    console.log(data);
    if (data.email && data.password && data.confirmPassword) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  return (
    <Layout>
      <div className="flex flex-col w-full md:w-[438px] max-h-[calc(100vh_-_100px)] overflow-auto">
        <div className="rounded-lg p-6 bg-white flex flex-col mx-3">
          <div className="text-dark1 text-2xl font-semibold leading-loose">
            Forgot password?
          </div>
          <span className="text-dark2 text-sm font-normal leading-normal ">
            No worriest! Just enter your email and we’ll send you a reset
            password link.
          </span>
          <div className="my-6 flex flex-col gap-5">
            <div className="flex flex-col gap-1.5">
              <span className="text-black text-xs font-medium">Email</span>
              <TextInput
                placeholder="Your email"
                className="w-full !p-[14px]"
                name="email"
              />
            </div>
          </div>
          <Button
            disabled={disabled}
            loading={loading}
            className="w-full"
            variant="primary"
          >
            Send Recovery Email
          </Button>
        </div>
        <div className="flex text-sm text-dark2 gap-1.5 mt-4 justify-center">
          Just remember?
          <Link
            to="/login"
            className="text-black border-b pb-1 border-dashed border-primary font-medium"
          >
            Sign in
          </Link>
        </div>
      </div>
    </Layout>
  );
}
