import { useEffect, useMemo, useState } from "react";

import { ReactComponent as Library } from "../../assets/ic_my_library_books.svg";
import { ReactComponent as People } from "../../assets/ic_people.svg";
import { ReactComponent as Poll } from "../../assets/ic_poll.svg";
import { ReactComponent as PersonAdd } from "../../assets/ic_person_add.svg";
import { ReactComponent as ThumbDown } from "../../assets/ic_thumb_down.svg";
import { ReactComponent as ThumbUp } from "../../assets/ic_thumb_up.svg";
import { ReactComponent as StarIcon } from "../../assets/star.svg";
import { ReactComponent as Favourite } from "../../assets/ic_favorite.svg";
import { ReactComponent as Bookmark } from "../../assets/ic_bookmark.svg";
import { ReactComponent as Send } from "../../assets/ic_send.svg";
import { ReactComponent as CommentIcon } from "../../assets/ic_comment.svg";
import userImage from "../../assets/user.png";
import { Link } from "react-router-dom";
import React from "react";
import { HighlightCard } from "../users/View/progress/components/HightlightCard";
import { SelectInput } from "../../components/SelectInput";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { api } from "../../services/api";
import { getUser } from "../../services/utils";
import { chartFilters, chartFiltersLabel } from "../../constant";
import moment from "moment";
import { Loading } from "../../components/Loading/Loading";

const storyLabels = [
  "Start Here",
  "FAQs",
  "Freebies",
  "Testimonials",
  "Work with Me",
];

function CustomizedLabel({ x, y, payload, ...rest }) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={6} y={0} dy={16} textAnchor="end" fill="#666">
        {rest.type === chartFilters.CURRENT_WEEK
          ? moment(payload.value).format("ddd")
          : rest.type === chartFilters.CURRENT_MONTH
          ? moment(payload.value).format("DD MMM")
          : rest.type === chartFilters.CURRENT_YEAR
          ? moment(payload.value).format("MMM")
          : payload.value}
      </text>
    </g>
  );
}

const renderUsersTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <div className="px-5 py-4 bg-[#F4EFE5] rounded-lg shadow flex-col justify-center items-center gap-1 flex">
          <div>
            <span className="text-[#484848] text-xs">
              {moment(payload[0]?.payload?.day).format("MMM DD | ddd")}
            </span>
          </div>
          <div className="text-black text-base font-normal leading-tight">
            <b>{payload[0]?.payload?.posts}</b> Post
            {payload[0]?.payload?.posts > 1 ? "s" : ""}
          </div>
          <div className="text-black text-base leading-normal">
            <b>{payload[0]?.payload?.users}</b> User
            {payload[0]?.payload?.users > 1 ? "s" : ""}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

function ChartLegend({
  data,
  selectedFilter,
}: {
  data: any;
  selectedFilter: any;
}) {
  const dateRange = useMemo(() => {
    // calculate date range using moment
    if (selectedFilter === "CURRENT_WEEK") {
      const start = moment().startOf("isoWeek");
      const end = moment().endOf("isoWeek");
      return `${start.format("MMM DD")} - ${end.format("MMM DD")}`;
    } else if (selectedFilter === "CURRENT_MONTH") {
      const start = moment().startOf("month");
      const end = moment().endOf("month");
      return `${start.format("MMM DD")} - ${end.format("MMM DD")}`;
    } else if (selectedFilter === "CURRENT_YEAR") {
      const start = moment().startOf("year");
      const end = moment().endOf("year");
      return `${start.format("MMM DD")} - ${end.format("MMM DD")}`;
    }
    return "";
  }, [selectedFilter]);

  return (
    <div className="pl-6 pr-4 py-4 bg-neutral-100 rounded justify-between items-center flex">
      <div className="text-right text-zinc-500 text-sm font-medium font-['Inter']">
        {dateRange}
      </div>
      <div className="flex-col justify-start items-end gap-0.5 inline-flex">
        <div className="justify-start items-center gap-2 inline-flex">
          <div className="w-5 h-3.5 bg-[#C89721] rounded" />
          <div className="w-10 text-neutral-800 text-sm font-normal font-['Inter']">
            Posts
          </div>
          <div className="w-16 text-neutral-800 text-base font-bold font-['Inter']">
            {data.reduce((acc: any, curr: any) => acc + curr.posts, 0)}
          </div>
        </div>
        <div className="justify-start items-center gap-2 inline-flex">
          <div className="w-5 h-3.5 bg-[#97C4CE] rounded" />
          <div className="w-10 text-neutral-800 text-sm font-normal font-['Inter']">
            Users
          </div>
          <div className="w-16 text-neutral-800 text-base font-bold font-['Inter']">
            {data.reduce((acc: any, curr: any) => acc + curr.users, 0)}
          </div>
        </div>
      </div>
    </div>
  );
}

export function Dashboard() {
  const adminUser = getUser();
  const [userPostSelectedFilter, setUserPostSelectedFilter] =
    useState<string>("CURRENT_WEEK");
  const [dashboardData, setDashboardData] = useState<any>(null);
  const [chartReport, setChartReport] = useState<any>([]);
  const [socialScoreTab, setSocialScoreTab] = useState<string>("highest");
  const [socialScoreSelectedFilter, setSocialScoreSelectedFilter] =
    useState<string>("CURRENT_WEEK");
  const [socialScoreData, setSocialScoreData] = useState<any>([]);
  const [socialScoreLoading, setSocialScoreLoading] = useState<boolean>(false);

  const fetchUserPostChartReport = React.useCallback(() => {
    api
      .getChartReport({
        id: adminUser._id,
        reportType: "USERS_POSTS_COUNT",
        reportDuration: userPostSelectedFilter,
      })
      .then(
        (res) => {
          const data = res.posts.map((post: any) => ({
            posts: post.count,
            day: post.day,
            users: res.users.find((user: any) => user.day === post.day)?.count,
          }));
          setChartReport(data);
        },
        (err) => {
          console.log(err);
        }
      );
  }, [adminUser._id, userPostSelectedFilter]);

  const fetchSocialScoreChartReport = React.useCallback(() => {
    setSocialScoreLoading(true);
    api
      .getChartReport({
        id: adminUser._id,
        reportType:
          socialScoreTab === "highest"
            ? "USERS_WITH_HIGHEST_SCORE"
            : "USERS_WITH_LOWEST_SCORE",
        reportDuration: socialScoreSelectedFilter,
      })
      .then(
        (res) => {
          setSocialScoreData(res);
        },
        (err) => {
          console.log(err);
        }
      )
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSocialScoreLoading(false);
      });
  }, [adminUser._id, socialScoreSelectedFilter, socialScoreTab]);

  useEffect(() => {
    api.getDashboard(adminUser._id).then(
      (res) => {
        setDashboardData(res);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [adminUser._id]);

  useEffect(() => {
    fetchUserPostChartReport();
  }, [fetchUserPostChartReport]);

  useEffect(() => {
    fetchSocialScoreChartReport();
  }, [fetchSocialScoreChartReport]);
  return (
    <div className="p-6 bg-ultraLightGray h-[100vh]">
      <div className="mb-6">
        <h1 className="text-dark1 text-[24px] font-semibold">Dashboard</h1>
      </div>
      <div>
        <div className="flex gap-5 flex-wrap items-center mb-5">
          <HighlightCard
            label="Total Users"
            value={dashboardData?.totalUsers}
            icon={<People />}
            onClick={() => {}}
            className="flex-1"
          />
          <HighlightCard
            label="Total Posts"
            value={dashboardData?.totalPosts}
            icon={<Library />}
            onClick={() => {}}
            className="flex-1"
          />
        </div>
        <div className="flex items-center flex-wrap mb-5 gap-5">
          <div className="bg-white shadow p-6 flex-1 rounded-lg h-[66vh]">
            <div className="flex justify-between items-center mb-5 [&>div]:w-fit">
              <span className="text-base text-dark1 font-medium leading-tight whitespace-nowrap">
                Users
              </span>
              <SelectInput
                className="!p-2 bg-white"
                data={Object.values(chartFilters).map((filter) => ({
                  label: chartFiltersLabel[filter],
                  value: filter,
                }))}
                showBlankOption={false}
                value={userPostSelectedFilter}
                onChange={(e) => setUserPostSelectedFilter(e.target.value)}
              />
            </div>
            <ChartLegend
              data={chartReport}
              selectedFilter={userPostSelectedFilter}
            />
            <div className="h-[39vh] pt-10">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={200}
                  data={chartReport}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  barSize={8}
                >
                  <XAxis
                    dataKey="day"
                    tick={(props) => (
                      <CustomizedLabel
                        {...props}
                        type={userPostSelectedFilter}
                      />
                    )}
                  />
                  <Tooltip content={renderUsersTooltip} />
                  <Bar dataKey="posts" fill="#7DB5C2" label="Posts" />
                  <Bar dataKey="users" fill="#C89721" />
                  <YAxis />
                  <CartesianGrid vertical={false} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="h-[66vh] bg-white shadow p-6 flex-1 rounded-lg">
            <div className="flex justify-between items-center mb-5 [&>div]:w-fit">
              <div className="flex items-center gap-3">
                <button
                  className={`text-base text-dark1 font-medium leading-tight whitespace-nowrap ${
                    socialScoreTab !== "highest" ? "opacity-30" : ""
                  }`}
                  onClick={() => setSocialScoreTab("highest")}
                >
                  Highest social score
                </button>

                <button
                  className={`text-base text-dark1 font-medium leading-tight whitespace-nowrap ${
                    socialScoreTab !== "lowest" ? "opacity-30" : ""
                  }`}
                  onClick={() => setSocialScoreTab("lowest")}
                >
                  Lowest social score
                </button>
              </div>
              <SelectInput
                className="!p-2 bg-white flex"
                showBlankOption={false}
                data={Object.values(chartFilters).map((filter) => ({
                  label: chartFiltersLabel[filter],
                  value: filter,
                }))}
                value={socialScoreSelectedFilter}
                onChange={(e) => setSocialScoreSelectedFilter(e.target.value)}
              />
            </div>
            <div className="max-h-[50vh] h-full overflow-auto flex flex-col gap-3.5">
              {socialScoreLoading ? (
                <div className="[&>svg]:h-6 [&>svg]:w-6 flex flex-col flex-1 items-center justify-center">
                  <Loading />
                </div>
              ) : (
                <>
                  {socialScoreData.map((score, index) => (
                    <div
                      key={index}
                      className="flex flex-col gap-[10px] p-3 border border-lightGray rounded-lg"
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-[6px]">
                          <img
                            src={score.profilePictureUrl}
                            alt=""
                            className="w-9 h-9 mr-[2px] border-[#E8E8E8] border p-[2px] rounded-full"
                          />
                          <span className="text-sm font-medium">
                            {score.name}
                          </span>
                          <span className="text-sm text-dark3">
                            {score.instagram}
                          </span>
                        </div>
                        <div className="h-[35px] w-[70px]">
                          <AreaChart
                            height={35}
                            width={70}
                            data={[
                              {
                                post: score.initialSocialScore,
                                name: "initial",
                              },
                              { post: score.prevSocialScore, name: "previous" },
                              { post: score.socialScore, name: "latest" },
                            ]}
                          >
                            <defs>
                              <linearGradient
                                id="paint0_linear_53_758"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#E48570" />
                                <stop
                                  offset="1"
                                  stop-color="#E49A70"
                                  stop-opacity="0"
                                />
                              </linearGradient>
                            </defs>
                            <Area
                              type="monotone"
                              dataKey="post"
                              stroke="#FF8672"
                              strokeWidth="1.5px"
                              fill="url(#paint0_linear_53_758)"
                            />
                            <XAxis
                              dataKey="name"
                              hide
                              axisLine={false}
                              tick={false}
                            />
                            <YAxis axisLine={false} hide tick={false} />
                          </AreaChart>
                        </div>
                      </div>
                      <div className="bg-ultraLightGray rounded p-3 flex gap-5 items-center justify-center">
                        <div className="flex flex-col items-center">
                          <span className="text-xs text-dark3 text-center">
                            Initial Social score
                          </span>
                          <span className="text-sm font-medium text-dark1">
                            {score.initialSocialScore
                              ? Number(score.initialSocialScore).toFixed(0)
                              : score.initialSocialScore}
                          </span>
                        </div>
                        <div className="flex flex-col items-center">
                          <span className="text-xs text-dark3 text-center">
                            Previous Social score
                          </span>
                          <span className="text-sm font-medium text-dark1">
                            {score.prevSocialScore
                              ? Number(score.prevSocialScore).toFixed(0)
                              : score.prevSocialScore}
                          </span>
                        </div>
                        <div className="flex flex-col items-center">
                          <span className="text-xs text-dark3 text-center">
                            Current Social score
                          </span>
                          <span className="text-sm font-medium text-dark1">
                            {score.socialScore
                              ? Number(score.socialScore).toFixed(0)
                              : score.socialScore}
                          </span>
                        </div>
                      </div>
                      <div className="bg-ultraLightGray rounded p-3 flex gap-5 items-center justify-center">
                        <div className="flex flex-col items-center">
                          <span className="text-xs text-dark3 text-center">
                            Initial Followers
                          </span>
                          <span className="text-sm font-medium text-dark1">
                            {score.initialFollowersCount}
                          </span>
                        </div>
                        <div className="flex flex-col items-center">
                          <span className="text-xs text-dark3 text-center">
                            Previous Followers
                          </span>
                          <span className="text-sm font-medium text-dark1">
                            {score.prevFollowersCount}
                          </span>
                        </div>
                        <div className="flex flex-col items-center">
                          <span className="text-xs text-dark3 text-center">
                            Current Followers
                          </span>
                          <span className="text-sm font-medium text-dark1">
                            {score.followersCount}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
