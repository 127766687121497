import { Button } from "../../../../../components/Button";
import { Modal } from "../../../../../components/Modal";

export function PostDeleteConfirm({
  open,
  onClose,
  onConfirm,
  deletingPost,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
    deletingPost: boolean;
}) {
  return (
    <Modal
      contentClassName="max-w-[400px]"
      open={open}
      onClose={onClose}
      showCloseButton={false}
      modalClassName="!overflow-y-hidden !md:overflow-y-auto"
    >
      <div className="text-center">
        <div className="text-center text-[#000] text-2xl font-semibold leading-[34px]">
          Are you sure you want to delete post(s)?
        </div>
        <div className="mt-3.5 leading-[18px] text-center text-black text-sm font-normal">
          Please know that by removing posts, all posts will be deleted and
          can't be recovered. Do you still wish to proceed?
        </div>
        <div className="flex gap-2.5 justify-center mt-6">
          <Button disabled={deletingPost} variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button loading={deletingPost} onClick={onConfirm} variant="primary">
            Yes, Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
}
