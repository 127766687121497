import userImage from "../../../assets/user.png";
import { ReactComponent as Copy } from "../../../../../assets/ic_content.svg";
import { useState } from "react";
import { Button } from "../../../../../components/Button";

export function SocialMediaProfile({ profile }) {
  const [isCopied, setIsCopied] = useState(false);
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };
  console.log(profile);
  if (!profile) return null;

  return (
    <div className="max-w-[650px] mx-auto flex items-center flex-wrap gap-5 md:gap-20">
      <div className="w-[120px] h-[120px]">
        <img
          src={
            profile?.profilePictureUrl ||
            profile?.onboardingSubmissions?.IMAGES?.[0] ||
            profile?.profilePic
          }
          alt="profile-pic"
          className="w-full h-full object-cover rounded-full"
        />
      </div>
      <div className="flex-1 flex-col justify-start items-start gap-6 flex">
        <div className="justify-center items-center gap-5 inline-flex">
          <div className="text-gray-900 text-2xl font-light ">
            {profile?.onboardingSubmissions?.INSTAGRAM_HANDLE?.username}
          </div>
        </div>
        <div className="justify-start items-start gap-4 md:gap-16 inline-flex">
          <div className="flex items-center gap-2">
            <div className="text-center text-black text-sm font-medium">
              {profile?.mediaCount}
            </div>
            <div className="text-black text-xs font-normal ">Posts</div>
          </div>
          <div className="flex items-center gap-2">
            <div className="text-center text-black text-sm font-medium">
              {profile?.followersCount}
            </div>
            <div className="text-black text-xs font-normal">Followers</div>
          </div>
          <div className="flex items-center gap-2">
            <div className="text-center text-black text-sm font-medium">
              {profile?.followsCount}
            </div>
            <div className="text-black text-xs font-normal">Following</div>
          </div>
        </div>
        <div className="flex-col justify-start items-start gap-1 flex">
          <div className="text-black text-sm font-normal ">{profile?.name}</div>
          <div className="text-neutral-400 text-sm font-normal ">
            Product/service
          </div>
          <div className="text-black text-xs font-light flex gap-5">
            <span>{profile?.biography}</span>
            <Button
              variant="link"
              onClick={() => handleCopy(profile?.biography)}
            >
              <Copy
                className={`w-6 h-6 ${isCopied ? "[&_path]:fill-primary" : ""}`}
              />
            </Button>
          </div>
          <div className="text-sky-900 text-xs font-normal ">
            <a href={profile.website}> {profile.website}</a>
          </div>
        </div>
      </div>
    </div>
  );
}
