import { ReactComponent as CloseIcon } from "../../assets/x.svg";

export function Modal({
  children,
  onClose,
  open,
  contentClassName,
  showCloseButton = true,
  modalClassName = "",
}: {
  children: React.ReactNode;
  onClose: () => void;
  open: boolean;
  contentClassName?: string;
  showCloseButton?: boolean;
  modalClassName?: string;
}) {
  return (
    <div
      className={`fixed inset-0 z-50 overflow-y-auto backdrop-blur-sm ${modalClassName} ${
        open ? "block" : "hidden"
      }`}
    >
      <div className="flex items-center justify-center min-h-screen relative">
        <div className="fixed inset-0 bg-black bg-opacity-40" />
        <div
          className={`relative bg-white z-[2] rounded-md max-w-[665px] w-full mx-6 md:mx-auto px-5 pb-5 pt-7 flex flex-col ${contentClassName}`}
        >
          {showCloseButton && (
            <button
              className="absolute -right-8 top-0"
              onClick={onClose}
              aria-label="Close"
            >
              <CloseIcon className="w-6 h-6" />
            </button>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}
