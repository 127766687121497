import { Link, useNavigate, useSearchParams } from "react-router-dom";
import UIPopover from "../../../components/Popover/Popover";
import moment from "moment";
import { Button } from "../../../components/Button";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowRight } from "../../../assets/right-chevron.svg";
import { ReactComponent as More } from "../../../assets/More.svg";
import { ReactComponent as FiltersIcon } from "../../../assets/Filters.svg";
import { ReactComponent as SearchIcon } from "../../../assets/search.svg";
import { Filters } from "./components/Filters";
import { DeactivateConfirm } from "./components/DeactivateConfirm";
import { getUser } from "../../../services/utils";
import { api } from "../../../services/api";
import toast from "react-hot-toast";
import React from "react";
import { useDebounce } from "../../../services/useDebounce";
// import useUsers from "./useUsers";
import userImage from "../../../assets/user.png";
import { LoadingSkeleton } from "./List";
import { useUsers } from "./context/active-user-context";

export const playTypeMap = {
  ST: "standard",
  PR: "premium",
  PL: "plus",
};

export const typeMap = {
  M: "Monthly",
  Y: "Yearly",
};

const statusColors = [
  { bg: "#F4EFE5", text: "#C89721", name: "plus" },
  { bg: "#F0F0F0", text: "#59636D", name: "standard" },
  { bg: "#DCF1F2", text: "#2B434A", name: "premium" },
];

function PlanChip({ plan }) {
  const planColor = statusColors.find((status) => status.name === plan);
  return (
    <div
      className="px-2 py-1 rounded-md font-medium capitalize w-fit"
      style={{ backgroundColor: planColor?.bg, color: planColor?.text }}
    >
      {plan}
    </div>
  );
}

function PlanTypeChip({ planType }) {
  return (
    <div className="px-2 py-1 border-[#CFCCD3] border rounded-md font-medium capitalize w-fit">
      {planType}
    </div>
  );
}

export function ActiveUsers({ onTableChange }: { onTableChange: () => void }) {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const type = params.get("type") || "active";
  const user = getUser();
  const [loading, setLoading] = useState(false);
  // const [users, setUsers] = useState<any[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [deactivating, setDeactivating] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [deactivateUser, setDeactivateUser] = useState<any>(null);
  const [deactiveUserLength, setDeactiveUserLength] = useState<number>(0);
  const [removeUserModal, setRemoveUserModal] = useState<any>(null);

  // const [filters, setFilters] = useState({
  //   sortBy: "",
  //   sortOrder: "",
  //   searchKeyword: "",
  //   tier: [],
  //   startDate: "",
  //   endDate: "",
  // });

  const {
    updateFilters,
    filters,
    isLoading,
    reset,
    refetch,
    users,
    total,
    loadMore,
  } = useUsers();

  const onSortChange = (newFilters) => {
    updateFilters({ ...newFilters });
  };

  const handleSort = (key) => {
    if (key === filters.sortBy) {
      onSortChange({
        sortBy: filters.sortOrder === "desc" ? "" : key,
        sortOrder:
          filters.sortOrder === "asc"
            ? "desc"
            : filters.sortOrder === "desc"
            ? ""
            : "asc",
      });
    } else {
      onSortChange({ sortOrder: "asc", sortBy: key });
    }
  };
  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();
  const openAssignAE = (client, key) => () => {
    console.log(client, key);
  };
  const openFilters = () => {
    setShowFilters(true);
  };
  const closeFilters = () => {
    setShowFilters(false);
  };
  const handleDeactivateUserConfirm = () => {
    // api call
    setBlocking(true);
    api
      .blockUser(deactivateUser.id)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setDeactivateUser(null);
          refetch(user.id, true);
          toast.success("User deactivated successfully");
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
        console.log(err);
      })
      .finally(() => {
        setBlocking(false);
      });
  };

  const handleRemoveUserConfirm = () => {
    // api call
    const user = removeUserModal;
    setRemoving(true);
    api
      .deleteUser(user.id)
      .then((response) => {
        console.log(response);
        setRemoveUserModal(null);
        refetch(user.id, true);
        toast.success("User removed successfully");
      })
      .catch((err) => {
        toast.error("Something went wrong");
        console.log(err);
      })
      .finally(() => {
        setRemoving(false);
        setRemoveUserModal(null);
      });
  };

  const fetchDeactiveUsers = async () => {
    const response = await api.getUsers({
      showActive: false,
      page: 0,
      limit: 10,
    });
    if (response) {
      setDeactiveUserLength(response.totalUsers);
    }
  };

  const handleFilters = (filters) => {
    console.log(filters);
    updateFilters({ ...filters });
  };

  const handleResetFilters = () => {
    reset(true);
    closeFilters();
  };

  useEffect(() => {
    fetchDeactiveUsers();
  }, []);

  useEffect(() => {
    updateFilters({ showActive: true });
  }, []);

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          users &&
          users.length < total &&
          !isLoading
        ) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, users, total, isLoading]);

  return (
    <>
      <div className="bg-white rounded-lg shadow px-3 py-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <div className="rounded-lg border border-[#DDDDDD] p-3 flex gap-[6px] items-center">
              <SearchIcon />
              <input
                type="text"
                placeholder="Search"
                className="outline-none"
                onChange={(e) =>
                  updateFilters({
                    searchKeyword: e.target.value,
                  })
                }
                value={filters.searchKeyword}
              />
            </div>
            <div>
              <Button
                className="border-[#DDDDDD]"
                variant="outline"
                onClick={openFilters}
              >
                <FiltersIcon />
                <span>Filters</span>
              </Button>
            </div>
          </div>
          <div>
            <Button
              variant="link"
              className="border-dashed border-b border-primary !p-0 mr-5 rounded-none"
              onClick={onTableChange}
            >
              Deactivated users ({deactiveUserLength})
            </Button>
          </div>
        </div>
        <div className="mt-5 text-[14px] max-h-[70vh] overflow-auto">
          <table className="w-full">
            <thead className="[&_td]:bg-primaryLight [&_td]:py-4">
              <tr className="sticky top-0 z-[1]">
                <td className="rounded-l pl-5">
                  <div className="cursor-pointer flex items-center">
                    Name
                    {filters.sortBy === "client_name" ? (
                      <ArrowRight
                        stroke="#2A2B2F"
                        className={`sort-icon ${
                          filters.sortOrder === "asc"
                            ? "rotate-[270deg]"
                            : "rotate-[90deg]"
                        }`}
                      />
                    ) : null}
                  </div>
                </td>
                <td>
                  <div className="cursor-pointer flex items-center">
                    Instagram
                    {filters.sortBy === "total_active_subscription" ? (
                      <ArrowRight
                        stroke="#2A2B2F"
                        className={`sort-icon ${
                          filters.sortOrder === "asc"
                            ? "rotate-[270deg]"
                            : "rotate-[90deg]"
                        }`}
                      />
                    ) : null}
                  </div>
                </td>
                <td>
                  <div className="flex items-center">
                    Email
                    {/* {filters.sortBy === "manager_name" ? (
                    <ArrowRight
                      stroke="#2A2B2F"
                      className={`sort-icon ${
                        filters.sortOrder === "asc"
                          ? "rotate-[270deg]"
                          : "rotate-[90deg]"
                      }`}
                    />
                  ) : null} */}
                  </div>
                </td>
                <td>
                  <div className="flex items-center">
                    Joined on
                    {/* {filters.sortBy === "account_cs_manager" ? (
                    <ArrowRight
                      stroke="#2A2B2F"
                      className={`sort-icon ${
                        filters.sortOrder === "asc"
                          ? "rotate-[270deg]"
                          : "rotate-[90deg]"
                      }`}
                    />
                  ) : null} */}
                  </div>
                </td>
                <td>
                  <div className="cursor-pointer flex items-center">
                    {/* {activeTab === "inactive" ? "Past MRR" : "MRR"} */}
                    Plan
                    {filters.sortBy === "total_mrr" ? (
                      <ArrowRight
                        className={`sort-icon ${
                          filters.sortOrder === "asc"
                            ? "rotate-[270deg]"
                            : "rotate-[90deg]"
                        }`}
                      />
                    ) : null}
                  </div>
                </td>
                <td></td>
                <td>
                  <div className="flex items-center cursor-pointer">
                    Last activity
                    {filters.sortBy === "last_activity_date" ? (
                      <ArrowRight
                        className={`sort-icon ${
                          filters.sortOrder === "asc"
                            ? "rotate-[270deg]"
                            : "rotate-[90deg]"
                        }`}
                      />
                    ) : null}
                  </div>
                </td>
                <td className="rounded-r"></td>
              </tr>
            </thead>
            <tbody>
              {users.map((aclient) => (
                <tr
                  key={aclient.id}
                  className="cursor-pointer hover:bg-gray-100 [&_td]:py-3 border-b"
                  onClick={() => navigate(`/users/${aclient.id}`)}
                >
                  <td className="name pl-5">
                    <div className="flex items-center gap-3">
                      <img
                        src={aclient.instagramProfilePic || userImage}
                        className="w-10 h-10 rounded-full object-cover"
                        alt="user"
                      />
                      <h5 className="flex-1">
                        {aclient.firstName} {aclient.lastName}
                      </h5>
                    </div>
                  </td>
                  <td className="subscription">
                    {aclient.instagramHandle || "N/A"}
                  </td>
                  <td className="account-executive">
                    <span>{aclient.email}</span>
                  </td>
                  <td className="account-manager">
                    {moment(aclient.joinedOn).format("MMM DD,YYYY")}
                  </td>
                  <td className="mrr">
                    <PlanChip plan={playTypeMap[aclient.tier]} />
                  </td>
                  <td>
                    <PlanTypeChip planType={typeMap[aclient.planType]} />
                  </td>
                  <td>{moment(aclient.lastActive).fromNow()}</td>
                  <td onClick={stopPropagate}>
                    <UIPopover
                      trigger={
                        <span className="block p-2 border border-gray-270 rounded-lg">
                          <More />
                        </span>
                      }
                    >
                      {(close) => (
                        <Dropdown
                          clientStatus={aclient.status}
                          clientId={aclient.id}
                          removeUser={() => {
                            //   openRemoveClientModal(aclient)
                            setRemoveUserModal(aclient);
                          }}
                          onDeactiveUser={() => {
                            //   openInactiveClientModal(aclient)
                            setDeactivateUser(aclient);
                          }}
                          onActivateUser={() => console.log("mark as active")}
                        />
                      )}
                    </UIPopover>
                  </td>
                </tr>
              ))}
              {isLoading && <LoadingSkeleton />}
              <div ref={observerTarget}></div>
              <div className="h-5"></div>
            </tbody>
          </table>
        </div>
      </div>
      <Filters
        open={showFilters}
        onClose={closeFilters}
        onFilter={handleFilters}
        selectedFilters={filters}
        onReset={handleResetFilters}
      />
      <DeactivateConfirm
        open={!!deactivateUser}
        onClose={() => setDeactivateUser(null)}
        onConfirm={handleDeactivateUserConfirm}
        name={`${deactivateUser?.firstName} ${deactivateUser?.lastName}`}
        title={`Are you sure you want to deactivate account of ${
          `${deactivateUser?.firstName} ${deactivateUser?.lastName}` ||
          deactivateUser?.email
        }?`}
        description="User will not be able to access anything on this portal after deactivating."
        closeButtonText="Go back"
        confirmButtonText="Yes, deactivate" // block user
        loading={blocking}
      />
      <DeactivateConfirm
        open={!!removeUserModal}
        onClose={() => setRemoveUserModal(null)}
        onConfirm={handleRemoveUserConfirm}
        name={`${removeUserModal?.firstName} ${removeUserModal?.lastName}`}
        title={`Are you sure you want to remove the account of ${
          `${removeUserModal?.firstName} ${removeUserModal?.lastName}` ||
          removeUserModal?.email
        }?`}
        description="All the data of this user will be deleted permanently from this portal."
        closeButtonText="Go back"
        confirmButtonText="Yes, Remove" // remove user
        loading={removing}
      />
    </>
  );
}

const Dropdown = ({
  clientId,
  onActivateUser,
  onDeactiveUser,
  removeUser,
  clientStatus,
}) => {
  return (
    <div className="z-10 w-40 bg-white divide-y divide-gray-100 shadow-[0_4px_24px_rgba(0,0,0,0.15)] rounded-xl py-2 mr-8">
      <ul
        className="py-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        <li>
          <Link to={`/users/${clientId || "123"}`}>
            <button className="block py-2 px-4 hover:bg-gray-100 w-full text-left">
              View details
            </button>
          </Link>
        </li>
        {clientStatus !== "inactive" ? (
          <li>
            <button
              className="block py-2 px-4  hover:bg-gray-100 w-full text-left"
              onClick={onDeactiveUser}
            >
              De-activate user
            </button>
          </li>
        ) : (
          <li>
            <button
              className="block py-2 px-4  hover:bg-gray-100 w-full text-left"
              onClick={onActivateUser}
            >
              Activate user
            </button>
          </li>
        )}
        <li>
          <button
            className="block py-2 px-4 text-red-900  hover:bg-gray-100 w-full text-left"
            onClick={removeUser}
          >
            Remove user
          </button>
        </li>
      </ul>
    </div>
  );
};
