import { useEffect, useState } from "react";
import { LoadingScreen } from "../../../profile/LoadingScreen";
import { HighlightCard } from "./components/HightlightCard";

import { ReactComponent as Library } from "../../../../assets/ic_my_library_books.svg";
import { ReactComponent as People } from "../../../../assets/ic_people.svg";
import { ReactComponent as Poll } from "../../../../assets/ic_poll.svg";
import { ReactComponent as PersonAdd } from "../../../../assets/ic_person_add.svg";
import { ReactComponent as ThumbDown } from "../../../../assets/ic_thumb_down.svg";
import { ReactComponent as ThumbUp } from "../../../../assets/ic_thumb_up.svg";
import { ReactComponent as Favourite } from "../../../../assets/ic_favorite.svg";
import { ReactComponent as Bookmark } from "../../../../assets/ic_bookmark.svg";
import { ReactComponent as Send } from "../../../../assets/ic_send.svg";
import { ReactComponent as CommentIcon } from "../../../../assets/ic_comment.svg";

import { useUser } from "../../../profile/context/user-context";
import userImage from "../../../../assets/user.png";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import instagramAPI from "../../../../services/instagram";
import axios from "axios";
import { formatNumber } from "../../../../services/utils";
import moment from "moment";
import { SelectInput } from "../../../../components/SelectInput";
import { api } from "../../../../services/api";

const calculatePostInsights = (data: any[]) => {
  if (!data)
    return {
      total: 0,
      max: {},
      min: {},
      maxPercentage: 0,
      minPercentage: 0,
    };
  const total = data?.reduce((acc: any, item: any) => {
    return acc + item.reach;
  }, 0);
  // get object with max value
  const max = data?.reduce(
    (prev: any, current: any) => (prev.reach > current.reach ? prev : current),
    {}
  );
  // get object with min value
  const min = data?.reduce(
    (prev: any, current: any) => (prev.reach < current.reach ? prev : current),
    {}
  );
  const maxPercentage = (max.reach / total) * 100;
  const minPercentage = (min.reach / total) * 100;
  return {
    total,
    max,
    min,
    maxPercentage,
    minPercentage,
  };
};

function CustomizedLabel({ x, y, payload, ...rest }) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={6} y={0} dy={16} textAnchor="end" fill="#666">
        {payload.value.split("")[0]}
      </text>
    </g>
  );
}

export function Progress({
  userId,
  userProfile,
}: {
  userId: string;
  userProfile: any;
}) {
  console.log(userProfile);

  const [filter, setFilter] = useState(
    moment().subtract(0, "months").format("YYYY-MM")
  );
  const [selectedWeeklyInsight, setSelectedWeeklyInsight] = useState(0);
  const [progressData, setProgressData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [fetchingPosts, setFetchingPosts] = useState(false);

  useEffect(() => {
    const month = moment(filter).format("MM");
    const year = moment(filter).format("YYYY");
    try {
      setLoading(true);
      api
        .getProgressDashboard(userId, month, year)
        .then((response) => {
          setLoading(false);
          setProgressData(response);
        })
        .catch((err) => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  }, [userId, filter]);

  const currentWeekData = progressData?.weeklyPostInsights
    ? progressData?.weeklyPostInsights[selectedWeeklyInsight]
    : [];

  const profileGrowthData = progressData?.profileGrowth?.weeklyData || [];

  const postInsights = calculatePostInsights(currentWeekData || []);

  if (loading) {
    return (
      <div className="relative bg-ultraLightGray p-4 md:p-6">
        <LoadingScreen />
      </div>
    );
  }

  console.log(progressData);

  if (!loading && Object.keys(progressData).length === 0) {
    return (
      <div className="relative bg-ultraLightGray flex flex-col overflow-auto min-h-[calc(100vh-350px)]">
        <div className="bg-white flex-1 p-6 rounded-lg shadow flex items-center justify-center">
          <div className="text-lg font-semibold text-center">
            Profile does not connected with any social media account.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative bg-ultraLightGray p-4 md:p-6">
      <div className="flex flex-wrap gap-4 md:justify-between items-center mb-8">
        <div className="flex justify-between items-center flex-1 [&>div]:!w-fit">
          <h1 className="text-neutral-800 text-4xl font-semibold leading-10">
            Progress
          </h1>
          <SelectInput
            data={[
              // last 12 months select like Jan 2021, Feb 2021, etc
              // if it is current month, then show this month as label
              // value must be equal or grater than joinedDate
              ...Array(12)
                .fill(0)
                .map((_, index) => ({
                  label:
                    index === 0
                      ? "This month"
                      : moment().subtract(index, "months").format("MMM YYYY"),
                  value: moment().subtract(index, "months").format("YYYY-MM"),
                }))
                .filter((item) => {
                  const joinedMonth = moment(userProfile?.joinedDate).startOf(
                    "month"
                  );
                  return moment(item.value)
                    .startOf("month")
                    .isSameOrAfter(joinedMonth);
                }),
            ]}
            value={filter}
            onChange={(e: any) => {
              setFilter(e.target.value);
              // handleRegenerate({...postForm, type: e.target.value})
            }}
            className="!py-2 !bg-white !rounded"
          />
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex gap-4 flex-wrap">
          <HighlightCard
            label="Total Posts"
            value={progressData?.totalPosts}
            icon={<Library />}
            progressColor={
              (progressData?.postsGrowthRate || 0) < 0
                ? "text-[#FC0000]"
                : "text-[#0AA630]"
            }
            progressRate={`${
              (progressData?.postsGrowthRate || 0) < 0 ? "-" : "+"
            }
            ${progressData?.postsGrowthRate || 0} %`}
            onClick={() => {}}
          />
          <HighlightCard
            label="Followers"
            value={progressData?.totalFollowers}
            icon={<People />}
            progressColor={
              (progressData?.followersGrowthRate || 0) < 0
                ? "text-[#FC0000]"
                : "text-[#0AA630]"
            }
            progressRate={`${
              (progressData?.followersGrowthRate || 0) < 0 ? "-" : "+"
            }
            ${progressData?.followersGrowthRate || 0} %`}
            onClick={() => {}}
          />
          <HighlightCard
            label="Following"
            value={progressData?.totalFollows}
            icon={<PersonAdd className="[&>path]:fill-primary" />}
            progressColor={
              (progressData?.followsGrowthRate || 0) < 0
                ? "text-[#FC0000]"
                : "text-[#0AA630]"
            }
            progressRate={`${
              (progressData?.followsGrowthRate || 0) < 0 ? "-" : "+"
            }
            ${progressData?.followsGrowthRate || 0} %`}
            onClick={() => {}}
          />
          <HighlightCard
            label="Engagement"
            value={
              progressData?.engagementPercentage
                ? progressData?.engagementPercentage.toFixed(2)
                : 0 + "%"
            }
            icon={<Poll />}
            progressColor={
              (progressData?.engagementPercentageGrowthRate || 0) < 0
                ? "text-[#FC0000]"
                : "text-[#0AA630]"
            }
            progressRate={`${
              (progressData?.engagementPercentageGrowthRate || 0) < 0
                ? "-"
                : "+"
            }
            ${progressData?.engagementPercentageGrowthRate || 0} %`}
            onClick={() => {}}
          />
        </div>
        <div className="flex gap-6 flex-wrap">
          <div className="shadow bg-white rounded-lg flex-1 p-6">
            <h3 className="text-lg font-semibold text-dark1">
              Profile growth & discovery
            </h3>
            <span className="text-dark3 text-sm font-medium">
              See insights on how your profile has grown and changed over time
            </span>
            <div className="flex items-center justify-end mb-10 gap-7">
              <div className="flex flex-col items-end">
                <span className="text-dark1 text-lg font-semibold">
                  {formatNumber(progressData?.profileGrowth?.impressions || 0)}
                </span>
                <div className="flex gap-2 items-center">
                  <div className="bg-[#E4C270] rounded w-3 h-3" />
                  <span className="text-dark3 text-sm">Impressions</span>
                </div>
              </div>
              <div className="flex flex-col items-end">
                <span className="text-dark1 text-lg font-semibold">
                  {formatNumber(progressData?.profileGrowth?.reach || 0)}
                </span>
                <div className="flex gap-2 items-center">
                  <div className="bg-[#B7D7E4] rounded w-3 h-3" />
                  <span className="text-dark3 text-sm">Reach</span>
                </div>
              </div>
              <div className="flex flex-col items-end">
                <span className="text-dark1 text-lg font-semibold">
                  {formatNumber(progressData?.profileGrowth?.growthRate || 0)}%
                </span>
                <div className="flex gap-2 items-center">
                  <span className="text-dark3 text-sm">Growth Rate</span>
                </div>
              </div>
            </div>
            <div className="w-full h-[300px] max-w-[732px]">
              <ResponsiveContainer>
                <BarChart
                  width={500}
                  height={300}
                  data={profileGrowthData.map((item) => ({
                    ...item,
                    day: moment(item.day).format("MMM DD"),
                  }))}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  barSize={8}
                >
                  <XAxis
                    dataKey="day"
                    scale="point"
                    padding={{ left: 10, right: 10 }}
                  />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="reach" fill="#B7D7E4" />
                  <Bar dataKey="impressions" fill="#E4C270" />
                  <CartesianGrid strokeLinecap="square" vertical={false} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="shadow bg-white rounded-lg w-[354px] p-6">
            <div className="flex items-center mb-8">
              <div className="flex-1">
                <h3 className="text-lg font-semibold text-dark1">
                  Post Insight
                </h3>
                <span>
                  {moment(currentWeekData[0]?.day).format("MMM DD")} -{" "}
                  {moment(currentWeekData[6]?.day).format("MMM DD")}
                </span>
              </div>
              <div className="[&>div]:!w-fit">
                <SelectInput
                  data={[
                    { label: "Week 1", value: 0 },
                    { label: "Week 2", value: 1 },
                    { label: "Week 3", value: 2 },
                    { label: "Week 4", value: 3 },
                  ]}
                  value={selectedWeeklyInsight}
                  onChange={(e: any) => {
                    setSelectedWeeklyInsight(e.target.value);
                  }}
                  className="!py-2 !bg-white !rounded"
                />
              </div>
            </div>
            <ResponsiveContainer width="100%" height="50%">
              <BarChart
                width={500}
                height={300}
                data={currentWeekData.map((item) => ({
                  ...item,
                  day: moment(item.day).format("dd"),
                }))}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                barSize={8}
              >
                <XAxis
                  dataKey="day"
                  scale="point"
                  padding={{ left: 10, right: 10 }}
                  tick={(props) => <CustomizedLabel {...props} />}
                />
                <Tooltip />
                <Bar
                  dataKey="reach"
                  fill="#688692"
                  background={{ fill: "#F4EFE5" }}
                />
              </BarChart>
            </ResponsiveContainer>
            <div>
              <div className="flex justify-between items-start gap-4 border-b border-lightGray pb-4 my-4">
                <div className="flex gap-4">
                  <ThumbUp />
                  <div className="flex flex-col">
                    <span className="text-sm font-medium">
                      Best performance
                    </span>
                    <span className="text-sm text-dark3 ">
                      {" "}
                      {moment(postInsights.max.day).format("dddd")}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col text-right">
                  <span className="text-dark1 text-sm font-medium">
                    {formatNumber(postInsights.max.reach || 0)}
                  </span>
                  {/* <span className="text-[#0AA630] text-sm font-medium">
                    +84.5 %
                  </span> */}
                </div>
              </div>

              <div className="flex justify-between items-start gap-4">
                <div className="flex gap-4">
                  <ThumbDown />
                  <div className="flex flex-col">
                    <span className="text-sm font-medium">
                      Worst performance
                    </span>
                    <span className="text-sm text-dark3 ">
                      {moment(postInsights.min.day).format("dddd")}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col text-right">
                  <span className="text-dark1 text-sm font-medium">
                    {formatNumber(postInsights.min.reach || 0)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-4 flex-wrap">
          <div className="shadow bg-white rounded-lg w-[354px] p-6">
            <h3 className="text-lg font-semibold text-dark1 mb-6">
              Insights overview
            </h3>
            <div>
              <div className="py-3 bg-white rounded-lg border border-lightGray gap-2">
                <div className="px-4 border-b border-lightGray flex-1 flex items-center gap-3 pb-3">
                  <div className="w-11 h-11 rounded-full">
                    <img
                      src={
                        progressData?.profilePictureUrl ||
                        userProfile?.onboardingSubmissions?.IMAGES?.[0] ||
                        userImage
                      }
                      alt="profile-pic"
                      className="w-full h-full rounded-full object-fit"
                    />
                  </div>
                  <div className="flex-col gap-0.5 flex">
                    <span className="text-dark1 text-sm font-medium">
                      {progressData?.name}
                    </span>

                    <span className="text-dark3 text-sm">
                      @
                      {progressData?.username ||
                        userProfile?.onboardingSubmissions?.INSTAGRAM_HANDLE
                          ?.username}
                    </span>
                  </div>
                </div>
                <div className="pt-3 px-6 flex justify-between items-center">
                  <div className="flex flex-col gap-2">
                    <span className="text-dark3 font-medium text-xs">POST</span>
                    <span className="text-dark1 font-medium text-xs">
                      {formatNumber(progressData.totalPosts || 0)}
                    </span>
                  </div>
                  <div className="flex flex-col gap-2">
                    <span className="text-dark3 font-medium text-xs">
                      FOLLOWERS
                    </span>
                    <span className="text-dark1 font-medium text-xs">
                      {formatNumber(progressData?.totalFollowers || 0)}
                    </span>
                  </div>
                  <div className="flex flex-col gap-2">
                    <span className="text-dark3 font-medium text-xs">
                      FOLLOWING
                    </span>
                    <span className="text-dark1 font-medium text-xs">
                      {formatNumber(progressData?.totalFollows || 0)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-start gap-4 border-b border-lightGray pb-4 mb-4 mt-8">
                <span className="text-sm font-medium">Best performance</span>
                <div className="flex flex-col text-right">
                  <span className="text-dark1 text-sm font-medium">
                    {formatNumber(progressData.bestPerformance || 0)}
                  </span>
                  <span
                    className={`${
                      (progressData?.bestPerformanceGrowthRate || 0) < 0
                        ? "text-[#FC0000]"
                        : "text-[#0AA630]"
                    } text-sm font-medium`}
                  >
                    {(progressData?.bestPerformanceGrowthRate || 0) < 0
                      ? "-"
                      : "+"}
                    {progressData?.bestPerformanceGrowthRate || 0} %
                  </span>
                </div>
              </div>
              <div className="flex justify-between items-start gap-4">
                <span className="text-sm font-medium">
                  Content Interactions
                </span>
                <div className="flex flex-col text-right">
                  <span className="text-dark1 text-sm font-medium">
                    {formatNumber(progressData.contentInteractions || 0)}
                  </span>
                  <span
                    className={`${
                      (progressData?.contentInteractionsGrowthRate || 0) < 0
                        ? "text-[#FC0000]"
                        : "text-[#0AA630]"
                    } text-sm font-medium`}
                  >
                    {(progressData?.contentInteractionsGrowthRate || 0) < 0
                      ? "-"
                      : "+"}
                    {progressData?.contentInteractionsGrowthRate || 0} %
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="shadow bg-white rounded-lg flex-1 p-6">
            <h3 className="text-lg font-semibold text-dark1">
              Top 3 Performing posts
            </h3>
            <span className="text-dark3 text-sm font-medium">
              Quickly see which 3 posts have the best engagement from March 21 -
              April 21
            </span>
            <div className="flex flex-col gap-4 mt-4">
              {progressData?.topPosts?.map((item) => (
                <div
                  key={item._id}
                  className="flex flex-col md:flex-row justify-between gap-4 border-b border-lightGray pb-4"
                >
                  <div className="flex gap-4 items-center">
                    <div>
                      <img
                        src={item.mediaUrl}
                        alt="post"
                        className="w-16 h-16 object-cover"
                      />
                    </div>
                    <div className="flex flex-col gap-3">
                      <div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-[60vw] md:max-w-[250px]">
                        {item.caption}
                      </div>
                      <div className="hidden md:flex gap-4 items-center">
                        <div className="flex items-center gap-1">
                          <Favourite />
                          <span className="text-dark3 text-sm">
                            {formatNumber(item.likesCount || 0)}
                          </span>
                        </div>
                        <div className="flex items-center gap-1">
                          <CommentIcon />
                          <span className="text-dark3 text-sm">
                            {formatNumber(item.commentsCount || 0)}
                          </span>
                        </div>
                        <div className="flex items-center gap-1">
                          <Send />
                          <span className="text-dark3 text-sm">
                            {formatNumber(item.sendsCount || 0)}
                          </span>
                        </div>
                        <div className="flex items-center gap-1">
                          <Bookmark />
                          <span className="text-dark3 text-sm">
                            {formatNumber(item.sharesCount || 0)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="md:hidden flex gap-4 items-center">
                    <div className="flex items-center gap-1">
                      <Favourite />
                      <span className="text-dark3 text-sm">
                        {formatNumber(item.likesCount || 0)}
                      </span>
                    </div>
                    <div className="flex items-center gap-1">
                      <CommentIcon />
                      <span className="text-dark3 text-sm">
                        {formatNumber(item.commentsCount || 0)}
                      </span>
                    </div>
                    <div className="flex items-center gap-1">
                      <Send />
                      <span className="text-dark3 text-sm">
                        {formatNumber(item.sendsCount || 0)}
                      </span>
                    </div>
                    <div className="flex items-center gap-1">
                      <Bookmark />
                      <span className="text-dark3 text-sm">
                        {formatNumber(item.sharesCount || 0)}
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-8 items-center">
                    <div className="flex flex-col gap-3">
                      <span className="text-xs text-dark3 font-medium">
                        REACH
                      </span>
                      <span className="text-dark1 text-sm">
                        {formatNumber(item.reachCount || 0)}
                      </span>
                    </div>
                    <div className="flex flex-col gap-3">
                      <span className="text-xs text-dark3 font-medium">
                        ENGAGEMENT
                      </span>
                      <div className="flex items-center gap-[15px]">
                        <div className="flex-1 w-[128px] h-2 overflow-hidden rounded-full bg-gray-200">
                          <div
                            className="h-2 rounded-full bg-[#688692]"
                            style={{
                              width: `${item.engagementPercentage || 0}%`,
                            }}
                          ></div>
                        </div>
                        <span className="w-10">
                          {item.engagementPercentage
                            ? item.engagementPercentage.toFixed(0)
                            : 0}
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {fetchingPosts && <LoadingScreen />}
    </div>
  );
}
