import React, { useEffect, useState } from "react";
import { useDebounce } from "../../../../services/useDebounce";
import { api } from "../../../../services/api";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ActiveUserContext = React.createContext<any>(null!);
const LIMIT = 20;

function ActiveUserProvider({ children }: { children: React.ReactNode }) {
  const [userTab, setUserTab] = useState("active");
  const [pagination, setPagination] = useState({
    page: 0,
    limit: LIMIT,
  });
  const [state, setState] = useState<any>({
    searchKeyword: "",
    // status: "active",
    sort_by: "",
    sort_order: "",
    startDate: "",
    endDate: "",
    tier: [],
    planTypes: [],
    showActive: true,
  });

  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const debouncedFilters = useDebounce(state, 500);

  const fetchUsers = async (
    params,
    pageInfo = pagination,
    refetchId?: string,
    isDeleted?: boolean
  ) => {
    setIsLoading(true);
    const { totalUsers, ...rest } = await api.getUsers({
      ...params,
      ...pageInfo,
    });
    const users = rest.users;
    setIsLoading(false);
    // filter deleted question
    if (isDeleted && refetchId) {
      setData((prev) => prev.filter((item) => item.id !== refetchId));
    } else if (refetchId) {
      setData((prev) => [
        ...prev.filter((item) => item.id !== refetchId),
        ...users,
      ]);
    } else {
      if (pageInfo.page === 0) {
        setData([...users]);
      } else {
        setData((prev) => [...prev, ...users]);
      }
    }
    setTotal(totalUsers);
  };

  const refetch = (refetchId?: string, isDeleted?: boolean) => {
    fetchUsers(debouncedFilters, pagination, refetchId, isDeleted);
  };

  // const { data, isLoading, isFetching, refetch } = useQuery(
  //   [
  //     `users-${type}`,
  //     debouncedFilters.page,
  //     debouncedFilters.keyword,
  //     debouncedFilters.type,
  //     debouncedFilters.is_plus_user,
  //     //   debouncedFilters.status,
  //     debouncedFilters.limit,
  //   ],
  //   () => api.getUsers(state),
  //   {
  //     enabled: !!debouncedFilters,
  //     refetchOnMount: true,
  //     refetchOnWindowFocus: false,

  //     // keepPreviousData: true,
  //   }
  // );

  const loadMore = () => {
    // update page only we have data
    if (data?.length > 0) {
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
      fetchUsers(debouncedFilters, {
        ...pagination,
        page: pagination.page + 1,
      });
    }
  };

  const handleFilters = (updates: any) => {
    // update filters & reset to first page
    console.log("updates", updates);
    setIsLoading(true);
    setTimeout(() => {
      setData([]);
    }, 0);
    setState((prev) => ({ ...prev, ...updates }));
    setPagination((prev) => ({ ...prev, page: 0 }));
  };

  useEffect(() => {
    console.log("debouncedFilters", debouncedFilters);
    fetchUsers(debouncedFilters);
  }, [debouncedFilters]);

  const users: any[] = data || [];
  const value = {
    users,
    totalPages: Math.ceil(total / LIMIT),
    total,
    isLoading: isLoading,
    isFetching,
    filters: state,
    loadMore,
    refetch,
    updateFilters: (updates) => handleFilters(updates),
    reset: (showActive) => {
      setData([]);
      setState({
        page: 0,
        searchKeyword: "",
        // status: "active",
        limit: LIMIT,
        sort_by: "",
        sort_order: "",
        showActive,
        startDate: "",
        endDate: "",
        tier: [],
        planTypes: [],
      });
    },
    userTab,
    setUserTab,
  };

  return (
    <ActiveUserContext.Provider value={value}>
      {children}
    </ActiveUserContext.Provider>
  );
}

function useUsers() {
  return React.useContext(ActiveUserContext);
}

export { ActiveUserProvider, useUsers };
