import { Button } from "../../../../components/Button";
import { ReactComponent as Email } from "../../../../assets/sms.svg";
import { ReactComponent as Instagram } from "../../../../assets/instagram.svg";

export function UserInfoCard({
  profile,
  onRemove,
  onDeactivate,
  onEdit,
}: {
  profile: any;
  onRemove: any;
  onDeactivate: any;
  onEdit: any;
}) {
  console.log(profile);
  const { onboardingSubmissions } = profile;
  return (
    <div className="bg-white shadow p-6 rounded-lg flex justify-between items-center">
      <div className="flex items-center gap-7">
        <div className="w-[113px] h-[113px]">
          <img
            src={
              profile.profilePictureUrl ||
              onboardingSubmissions?.IMAGES?.[0] ||
              profile.profilePic
            }
            alt=""
            className="w-full h-full object-cover rounded-full"
          />
        </div>
        <div className="flex flex-col gap-3">
          <span className="text-dark1 text-[24px] font-bold">
            {onboardingSubmissions?.FIRST_NAME}{" "}
            {onboardingSubmissions?.LAST_NAME}
          </span>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <Email className="h-5 w-5" />
              <span className="text-base text-[#59636D]">{profile.email}</span>
            </div>
            <div className="w-[1px] h-[10px] bg-black/20" />
            <div className="flex items-center gap-2">
              <Instagram className="h-5 w-5" />
              <span className="text-base text-[#59636D]">
                @
                {typeof profile?.onboardingSubmissions?.INSTAGRAM_HANDLE ===
                "string"
                  ? profile?.onboardingSubmissions?.INSTAGRAM_HANDLE
                  : profile?.onboardingSubmissions?.INSTAGRAM_HANDLE?.username}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2.5 items-end">
        <Button
          variant="link"
          className="!p-0 text-sm !text-[#59636D] font-medium"
          onClick={onEdit}
        >
          Edit profile
        </Button>
        <Button
          variant="link"
          className="!p-0 text-sm !text-[#59636D] font-medium"
          onClick={onDeactivate}
        >
          Deactivate user
        </Button>
        <Button
          variant="link"
          className="!p-0 text-sm !text-[#FC0000] font-medium"
          onClick={onRemove}
        >
          Remove user
        </Button>
      </div>
    </div>
  );
}
