import { ReactComponent as PersonIcon } from "../../../../../assets/ic_person_add.svg";
import { ChipInput } from "../../../../../components/ChipInput/ChipInput";

export function TagPeople({value, onChange}) {
    const handleAdd = (newValue) => {
        onChange([...value, newValue]);
    }

    const handleRemove = (index) => {
        const newValue = value.slice();
        newValue.splice(index, 1);
        onChange(newValue);
    }
    console.log(value);
    return (
        <div className="px-4 py-3 flex gap-3 items-center">
            <PersonIcon />
            <div>
                <ChipInput 
                    chips={value} 
                    onSubmit={handleAdd} 
                    onRemove={handleRemove}
                />
            </div>
        </div>
    )
}