import "react-big-calendar/lib/css/react-big-calendar.css";
import { useEffect, useState } from "react";
import { Button } from "../../../../components/Button";
import { ReactComponent as DeleteIcon } from "../../../../assets/ic_delete.svg";
import { getUser } from "../../../../services/utils";
import { api } from "../../../../services/api";
import { PostDeleteConfirm } from "./components/PostDeleteConfirm";
import { LoadingScreen } from "../../../profile/LoadingScreen";
import { CreatePostForm } from "../schedule/components/CreatePostModal";
import React from "react";
import { fileUpload } from "../../../../services/file-upload";
import { ImageView } from "./components/ImageView";
import { ShowToast } from "../../../../services/toast";

export function MediaLibrary({ userId, userProfile }) {
  const uploadRef = React.useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false);
  const [fetchingPosts, setFetchingPosts] = useState(false);
  const [deletingPost, setDeletingPost] = useState(false);
  const [showAiGenerator, setShowAiGenerator] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [postDialog, setPostDialog] = useState(false);
  const [postForm, setPostForm] = useState<any>({});
  const [postToDelete, setPostToDelete] = useState<any[]>([]);
  const [mediaList, setMediaList] = useState<any[]>([]);
  const [saving, setSaving] = useState(false);

  const handleCreatePost = () => {
    setPostDialog(true);
    setPostForm({
      type: "CALL_TO_ACTION",
    });
  };

  const fetchMedia = async (userId: string, refreshOnUpload?: boolean) => {
    try {
      !refreshOnUpload && setFetchingPosts(true);
      const res = await api.searchMedia(userId);
      console.log(res);
      setMediaList([...res.filter((item) => !item.isDeleted)]);
      !refreshOnUpload ? setFetchingPosts(false) : setUploading(false);
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Failed to fetch media",
      });
      setFetchingPosts(false);
      setUploading(false);
    }
  };

  const handleAutoPostGenerate = async () => {
    setShowAiGenerator(true);
    // setGeneratingPost(true);
    // const result = await api.createPost({
    //   userId: userId,
    //   creationMode: "AUTO",
    //   type: "SHARE_YOUR_STORY",
    // });
    // const res = await api.getPosts(userId);
    // setMediaList([...res]);
    // setGeneratingPost(false);
  };

  const handleCloseAiGenerator = () => {
    setShowAiGenerator(false);
  };

  const handleSchedule = (data: any) => {
    console.log(data);
    // update
    const object = {
      creationMode: "MANUAL",
      userId: userId,
      type: data.type,
      imageUrl: data.imageUrl,
      tags: data.tags,
      location: data.location,
      caption: data.caption,
      scheduleDateTime: data.scheduleDateTime,
    };
    setSaving(true);
    api.createPost(object).then(async (res) => {
      console.log(res);
      await fetchMedia(userId);
      setPostDialog(false);
      setSaving(false);
    });
    // api.schedulePost(userId, postForm._id, data).then((res) => {
    //   console.log(res);
    //   setPostDialog(false);
    // });
  };

  const regeneratePost = (form) => {
    api.updatePost(form._id, { ...form }).then((res) => {
      console.log(res);
      // setPostForm(res.data);
    });
  };

  const handleViewPost = (post: any) => {
    console.log(post);
    setPostDialog(true);
    setPostForm(post);
  };

  const handleSelectAll = () => {
    setPostToDelete([...mediaList]);
  };

  const handleClearAll = () => {
    setPostToDelete([]);
  };

  const handleRemove = () => {
    setShowDeleteConfirm(true);
  };

  const handleDeleteConfirm = async () => {
    setDeletingPost(true);
    for (let i = 0; i < postToDelete.length; i++) {
      await api.deleteMedia(postToDelete[i]._id);
    }
    setDeletingPost(false);
    setPostToDelete([]);
    setShowDeleteConfirm(false);
    await fetchMedia(userId);
  };

  const handleCloseDeleteConfirm = () => {
    setShowDeleteConfirm(false);
  };

  const handleUpload = async (files: any) => {
    console.log(files);
    if (files.length > 0) {
      try {
        setUploading(true);
        // upload all images
        const imageList: string[] = [];
        for (let i = 0; i < files.length; i++) {
          const image = files[i];
          const uploadResponse = await fileUpload(image, userId, "USER_MEDIA");
          // images[i] = uploadResponse.data;
          console.log(uploadResponse);
          if (uploadResponse.url) {
            imageList.push(uploadResponse.url);
            await api.saveMedia({
              userId: userId,
              imageUrl: uploadResponse.url,
            });
          }
        }
        fetchMedia(userId, true);
        console.log(imageList);
        // setUploading(false);
      } catch (e) {
        setUploading(false);
      }
    }
  };

  useEffect(() => {
    if (userId) {
      fetchMedia(userId);
    }
  }, [userId]);

  const isAllSelected = () => {
    if (!postToDelete.length) {
      return false;
    }
    return postToDelete
      .map((item) => item._id)
      .every((id) => mediaList.map((item) => item._id).includes(id));
  };

  if (fetchingPosts) {
    return (
      <div className="relative bg-ultraLightGray p-4 md:p-6">
        <LoadingScreen />
      </div>
    );
  }

  return (
    <div>
      <div className="flex flex-wrap gap-4 md:justify-between items-center mb-8">
        <div>
          <h1 className="text-neutral-800 text-4xl font-semibold leading-10">
            Media Library
          </h1>
          <span className="text-sm text-dark2">
            ({mediaList.length} media item{mediaList.length > 1 ? "s" : ""})
          </span>
        </div>
        <div className="flex gap-4 h-fit">
          <input
            type="file"
            className="hidden"
            ref={uploadRef}
            multiple
            accept="image/*"
            onChange={(e) => {
              console.log(e.target.files);
              const files = e.target.files;
              console.log(files);
              if (files?.length) {
                handleUpload(Array.from(files));
              }
              e.target.value = "";
            }}
          />
          <Button
            variant="primary"
            loading={uploading}
            onClick={() => uploadRef.current?.click()}
          >
            Upload
          </Button>
          <Button variant="primary" onClick={handleSelectAll}>
            Select all
          </Button>
          {isAllSelected() && (
            <Button variant="outline" onClick={handleClearAll}>
              Clear all
            </Button>
          )}
          {postToDelete.length > 0 && (
            <Button variant="outline" className="!px-3" onClick={handleRemove}>
              <DeleteIcon />
            </Button>
          )}
        </div>
      </div>
      <div className="rounded-lg shadow-[0px_1px_3px_0px_rgba(0,0,0,0.08)] bg-white flex flex-wrap">
        <div className="border-r border-lightGray p-6 flex-1 overflow-auto min-h-[calc(100vh-350px)]">
          {/* <PillarsView
            data={[
              ...mediaList
            ]}
            setPostToDelete={setPostToDelete}
            postToDelete={postToDelete}
            onView={handleViewPost}
          /> */}
          <ImageView
            data={[...mediaList]}
            postToDelete={postToDelete}
            onView={handleViewPost}
          />
          {mediaList.length === 0 && !fetchingPosts && (
            <div className="flex flex-col items-center justify-center gap-4 mt-12">
              <span className="text-lg font-semibold text-neutral-700">
                No media found
              </span>
            </div>
          )}
        </div>
        {/* <div className="w-[300px] p-6">
          <Filters data={[...mediaList]} />
        </div> */}
      </div>
      <PostDeleteConfirm
        onClose={handleCloseDeleteConfirm}
        onConfirm={handleDeleteConfirm}
        open={showDeleteConfirm}
        deletingPost={deletingPost}
      />
      <CreatePostForm
        open={postDialog}
        onClose={() => {
          console.log("close");
          setPostForm({});
          setPostDialog(false);
        }}
        userProfile={userProfile}
        postForm={postForm}
        setPostForm={setPostForm}
        onSchedule={handleSchedule}
        regeneratePost={regeneratePost}
        onRefresh={() => {
          setPostForm({});
          setPostDialog(false);
          fetchMedia(userId);
        }}
        saving={saving}
      />
    </div>
  );
}
