import moment from "moment";
import apiClient from "./axios";

export const api = {
  login: (data: any) => {
    return apiClient.post("/auth/admin/login", data);
  },
  socialLogin: (data: any) => {
    return apiClient.post("/auth/login/social", data);
  },
  initEmailVerification: (id: any) => {
    return apiClient.post(`/users/${id}/email/verify/initiate`);
  },
  verifyEmail: (id: any, data: any) => {
    return apiClient.post(`/users/${id}/email/verify`, data);
  },
  adminSignup: (data: any) => {
    return apiClient.post("/auth/admin/register", data).then((res) => res.data);
  },
  signup: (data: any) => {
    return apiClient.post("/auth/register", data).then((res) => res.data);
  },
  logout: () => {
    return apiClient.post("/auth/logout").then((res) => res.data);
  },
  generateCsv: (id: string) => {
    return apiClient.post(`/users/${id}/generate-csv`);
  },
  getOnboardingSections: () => {
    return apiClient.get("/onboarding/sections").then((res) => res.data);
  },
  getOnboardingSection: (code: string) => {
    return apiClient
      .get(`/onboarding/sections/${code}`)
      .then((res) => res.data);
  },
  saveOnboardingSection: (code: string, data: any) => {
    return apiClient.put(`/onboarding/sections/${code}`, data);
  },
  getUsers: (params?: any) => {
    let url = `/users?page=${params?.page}&limit=${params?.limit}`;

    if (params?.tier && params?.tier.length > 0) {
      url += `&tiers=${params.tier.join(",")}`;
    }
    if (params?.planTypes && params?.planTypes.length > 0) {
      url += `&planTypes=${params.planTypes.join(",")}`;
    }
    if (params?.showActive === false) {
      url += `&showActive=false`;
    }
    if (params?.showActive === true) {
      url += `&showActive=true`;
    }
    if (params?.startDate && params?.endDate) {
      const formattedStartDate = moment(params.startDate).format("yyyy-MM-DD");
      const formattedEndDate = moment(params.endDate).format("yyyy-MM-DD");
      url += `&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    }
    if (params?.searchKeyword) {
      if (!url.includes("?")) {
        url += `?`;
      } else {
        url += `&`;
      }
      url += `searchKeyword=${params.searchKeyword}`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  deleteUser: (id: string) => {
    return apiClient.delete(`/users/${id}`);
  },
  blockUser: (id: string) => {
    return apiClient.patch(`/users/${id}/status`, { isActive: false });
  },
  unblockUser: (id: string) => {
    return apiClient.patch(`/users/${id}/status`, { isActive: true });
  },
  getUserProfile: (id: string) => {
    return apiClient.get(`/users/${id}`).then((res) => res.data);
  },
  getChartReport: (reportData: any) => {
    return apiClient
      .get(
        `/users/${reportData.id}/reports?reportType=${reportData.reportType}&reportDuration=${reportData.reportDuration}`
      )
      .then((res) => res.data);
  },
  saveUserProfile: (id: string, data: any) => {
    return apiClient.patch(`/users/${id}`, data);
  },
  generatePrompt: (id: string) => {
    return apiClient.post(`/users/${id}/prompts`);
  },
  getBrandStyles: (id: string) => {
    return apiClient.get(`/users/${id}/brand-style`).then((res) => res.data);
  },
  saveBrandStyles: (id: string, data: any) => {
    return apiClient.patch(`/users/${id}/brand-style`, data);
  },
  getPosts: (userId: string) => {
    return apiClient.get(`/posts?userId=${userId}`).then((res) => res.data);
  },
  createPost: (data) => {
    return apiClient.post(`/posts`, data);
  },
  updatePost: (id: string, data: any) => {
    return apiClient.patch(`/posts/${id}`, data);
  },
  getPost: (id: string) => {
    return apiClient.get(`/posts/${id}`).then((res) => res.data);
  },
  deletePost: (id: string) => {
    return apiClient.delete(`/posts/${id}`);
  },
  saveMedia: (data: any) => {
    return apiClient.post("/media", data);
  },
  searchMedia: (userId: string) => {
    return apiClient.get(`/media?userId=${userId}`).then((r) => r.data);
  },
  deleteMedia: (id: string) => {
    return apiClient.delete(`media/${id}`);
  },
  getDashboard: (userId: string) => {
    return apiClient.get(`/users/${userId}/dashboard`).then((r) => r.data);
  },
  getProgressDashboard: (userId: string, month: string, year: string) => {
    return apiClient
      .get(`/users/${userId}/progress-dashboard?month=${month}&year=${year}`)
      .then((r) => r.data);
  },
  getPredesignedUrl: ({
    id,
    mimeType,
    uploadType,
  }: {
    id: string;
    mimeType: string;
    uploadType: string;
  }) => {
    let url = `/users/${id}/images/signed-url?mimeType=${mimeType}&uploadType=${uploadType}`;

    return apiClient.get(url).then((r) => r.data);
  },
};
