import axios from "axios";
import { api } from "./api";

export const fileUpload = async (file: File, id: string, uploadType: string) => {
  
  const { accessUrl, uploadUrl } = await api.getPredesignedUrl({
    id,
    mimeType: file.type,
    uploadType,
  });
  console.log(file);
  const response = await axios.put(uploadUrl, file, {
    headers: { 'Content-Type': file.type }
  });
  return { response, url: accessUrl };
};
