import { useEffect, useState } from "react";
import { LoadingScreen } from "../../../profile/LoadingScreen";

import { ReactComponent as Library } from "../../../assets/ic_my_library_books.svg";
import { ReactComponent as People } from "../../../assets/ic_people.svg";
import { ReactComponent as Poll } from "../../../assets/ic_poll.svg";
import { ReactComponent as PersonAdd } from "../../../assets/ic_person_add.svg";
import { ReactComponent as ThumbDown } from "../../../assets/ic_thumb_down.svg";
import { ReactComponent as ThumbUp } from "../../../assets/ic_thumb_up.svg";
import { ReactComponent as StarIcon } from "../../../../assets/star.svg";
import { ReactComponent as Favourite } from "../../../../assets/ic_favorite.svg";
import { ReactComponent as Bookmark } from "../../../../assets/ic_bookmark.svg";
import { ReactComponent as Send } from "../../../../assets/ic_send.svg";
import { ReactComponent as CommentIcon } from "../../../../assets/ic_comment.svg";
import userImage from "../../../../assets/user.png";
import { SocialScore } from "./components/SocialScore";
import { SocialMediaProfile } from "./components/SocialMediaProfile";
import { PostView } from "./components/PostView";
import { Link } from "react-router-dom";
import { Button } from "../../../../components/Button";
import { useUser } from "../../../profile/context/user-context";
import { api } from "../../../../services/api";
import { getUser } from "../../../../services/utils";
import React from "react";

const storyLabels = [
  "Start Here",
  "FAQs",
  "Freebies",
  "Testimonials",
  "Work with Me",
];

export function Dashboard({ userId, userProfile }) {
  const [loading, setLoading] = useState(false);
  const [dashboard, setDashboard] = useState<any>({});
  const [brandingStyle, setBrandingStyle] = useState<any>({});

  const fetchDashboardData = async (userId: string) => {
    setLoading(true);
    try {
      const res = await api.getDashboard(userId);
      console.log(res);
      setDashboard(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchBrandStyleInfo = (id) => {
    setLoading(true);
    api
      .getBrandStyles(id)
      .then((response) => {
        console.log(response);
        setBrandingStyle(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRefresh = () => {
    fetchDashboardData(userId);
  };

  useEffect(() => {
    if (!userId) return;
    fetchDashboardData(userId);
    fetchBrandStyleInfo(userId);
  }, [userId]);

  if (loading) {
    return (
      <div className="relative bg-ultraLightGray p-4 md:p-6">
        <LoadingScreen />
      </div>
    );
  }

  console.log(dashboard);
  return (
    <div>
      <div className="flex gap-4 flex-wrap">
        {!loading && Object.keys(dashboard).length === 0 ? (
          <div className="bg-white flex-1 p-6 rounded-lg shadow">
            <div className="text-lg font-semibold text-center mt-12">
              Profile does not connected with any social media account.
            </div>
          </div>
        ) : (
          <div className="bg-white flex-1 p-6 rounded-lg shadow">
            <SocialMediaProfile
              profile={{ ...dashboard.profile, ...userProfile }}
            />
            <div className="md:gap-10 flex flex-wrap items-center mt-12 lg:ml-16">
              {brandingStyle?.colors?.map((item, i) => (
                <div className="flex-1 md:flex-none flex flex-col justify-center gap-3 items-center">
                  <div className="w-10 h-10 md:w-[70px] md:h-[70px] border border-[#DFDFDF] p-1 rounded-full">
                    <div
                      className="w-full h-full rounded-full"
                      style={{ backgroundColor: item }}
                    >
                      {/* <StarIcon className="w-[30px] h-[30px] md:w-[60px] md:h-[60px]" /> */}
                    </div>
                  </div>
                  <span className="text-black text-[8px] md:text-xs">
                    {storyLabels[i]}
                  </span>
                </div>
              ))}
            </div>
            <PostView
              userProfile={userProfile}
              refresh={handleRefresh}
              posts={dashboard?.posts || []}
            />
          </div>
        )}
        <div className="flex flex-col gap-5">
          <SocialScore score={dashboard?.profile?.socialScore || 0} />
          <div className="bg-white shadow w-full md:w-[259px] p-6 rounded-lg">
            <h3 className="text-lg font-semibold text-dark1">
              Top 3 Performing posts
            </h3>
            <div className="flex flex-col gap-4 mt-4">
              {dashboard?.topPosts?.map((item) => (
                <div
                  key={item._id}
                  className="flex justify-between gap-4 border-b border-lightGray pb-4"
                >
                  <div className="flex flex-col gap-2 w-full">
                    <div className="flex gap-2">
                      <div className="w-16 h-16">
                        <img
                          src={item.mediaUrl}
                          alt="post"
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <span className="flex-1 font-medium text-sm text-dark1 line-clamp-2 max-h-[40px] text-ellipsis">
                        {item.caption}
                      </span>
                    </div>
                    <div className="flex gap-2 items-center">
                      <div className="flex items-center gap-1">
                        <Favourite />
                        <span className="text-dark3 text-sm">
                          {item.likesCount}
                        </span>
                      </div>
                      <div className="flex items-center gap-1">
                        <CommentIcon />
                        <span className="text-dark3 text-sm">
                          {item.commentsCount}
                        </span>
                      </div>
                      <div className="flex items-center gap-1">
                        <Send />
                        <span className="text-dark3 text-sm">
                          {item.sharesCount}
                        </span>
                      </div>
                      <div className="flex items-center gap-1">
                        <Bookmark />
                        <span className="text-dark3 text-sm">
                          {item.savesCount}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div className="flex justify-center">
                <Link to="/progress">
                  <Button variant="outline" className="!py-2">
                    Check your Progress
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
