import { Link, useNavigate, useSearchParams } from "react-router-dom";
import UIPopover from "../../../components/Popover/Popover";
import moment from "moment";
import { Button } from "../../../components/Button";
import { useEffect, useState } from "react";
import { ReactComponent as ArrowRight } from "../../../assets/right-chevron.svg";
import { ReactComponent as More } from "../../../assets/More.svg";
import { ReactComponent as FiltersIcon } from "../../../assets/Filters.svg";
import { ReactComponent as SearchIcon } from "../../../assets/search.svg";
import { Filters } from "./components/Filters";
import { DeactivateConfirm } from "./components/DeactivateConfirm";
import { getUser } from "../../../services/utils";
import { api } from "../../../services/api";
import toast from "react-hot-toast";
import React from "react";
import { ActiveUsers } from "./ActiveUsers";
import { DeactivatedUsers } from "./DeactivatedUsers";
import { useUser } from "../../profile/context/user-context";
import { useUsers } from "./context/active-user-context";

const tableMap = {
  activeUsers: "active",
  deactiveUsers: "deactive",
};

export function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr
          key={i}
          className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b"
        >
          <td className="name pl-5">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-full bg-gray-200 animate-pulse transition duration-50" />
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <div className="w-28 h-4 bg-gray-200 animate-pulse transition duration-50" />
                </div>
              </div>
            </div>
          </td>
          <td className="subscription">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td className="account-manager">
            <div className="w-28 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td className="account-executive">
            <div className="w-20 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td className="mrr">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-6 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
        </tr>
      ))}
    </>
  );
}

export function List() {
  const { userTab, setUserTab, reset } = useUsers();

  const handleTabChange = (tab: string) => {
    setUserTab(tab);
    reset(tab === tableMap.activeUsers);
  };

  return (
    <div className="p-6 bg-ultraLightGray h-[100vh]">
      <div className="mb-6">
        <h1 className="text-dark1 text-[24px] font-semibold">Manage users</h1>
      </div>
      {tableMap.activeUsers === userTab ? (
        <ActiveUsers
          onTableChange={() => handleTabChange(tableMap.deactiveUsers)}
        />
      ) : (
        <DeactivatedUsers
          onTableChange={() => handleTabChange(tableMap.activeUsers)}
        />
      )}
    </div>
  );
}
