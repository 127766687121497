import { useState } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as DashboardIcon } from "../../assets/dashboard.svg";
import { ReactComponent as Logo2 } from "../../assets/Logo.svg";
import { ReactComponent as LogoutIcon } from "../../assets/logout.svg";
import { ReactComponent as GuidelyIcon } from "../../assets/guidely-icon.svg";
import { ReactComponent as ProgressIcon } from "../../assets/progress.svg";
import { ReactComponent as CalendarIcon } from "../../assets/calendar.svg";
import { ReactComponent as PhotoLibraryIcon } from "../../assets/photo-library.svg";
import { ReactComponent as SupportIcon } from "../../assets/support.svg";
import { ReactComponent as SettingsIcon } from "../../assets/settings.svg";
import userImage from "../../assets/user.png";
import { ReactComponent as LibraryIcon } from "../../assets/local-library.svg";
import { ReactComponent as LiveHelpIcon } from "../../assets/live-help.svg";
import { ReactComponent as ChevronLeftIcon } from "../../assets/chevron-left.svg";
import { helpMenuItems, menuItems } from "./Sidebar";

const PROFILE_PIC =
  "https://scontent.fstv5-1.fna.fbcdn.net/v/t51.2885-15/393470545_363262946050114_2699725582897640060_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=7d201b&_nc_ohc=QnvOCbmlI1cAX-CBKHA&_nc_ht=scontent.fstv5-1.fna&edm=AL-3X8kEAAAA&oh=00_AfCJRu0jxavCkUKHt15MrG7gOlBxmY2yn1JIT-6gkSwJkQ&oe=653AE3BE";

export function MobileSidebar({
  isOpen,
  onClose,
  user,
}: {
  isOpen?: boolean;
  onClose?: () => void;
  user: any;
}) {
  const handleLogout = () => {};

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full z-50 bg-black bg-opacity-20 transform transition duration-300`}
    >
      <div className="flex h-[100vh] w-fit overflow-auto bg-white border-r border-gray-200 flex-col">
        <div className="self-stretch pl-6 pr-2 py-5 bg-white border-b border-gray-200 justify-between items-center flex">
          <div className="relative justify-center items-center flex">
            <Logo2 />
          </div>
          <button
            onClick={onClose}
            className="p-1 bg-red-50 rounded justify-start items-start gap-2.5 flex"
          >
            <ChevronLeftIcon />
          </button>
        </div>
        <div className="flex-col justify-between gap-5 flex h-[calc(100vh-180px)]">
          <div className="flex-col justify-start items-start flex w-full">
            {menuItems.map((item, index) => (
              <Link
                to={item.path}
                key={index}
                className="w-full px-6 py-3.5 bg-white justify-start items-center gap-3 flex group hover:bg-[#FBF2F0] transition duration-75 hover:scale-105"
                onClick={onClose}
              >
                <item.Icon
                  className={`group-hover:[&_circle]:stroke-primary ${
                    item.path === "/settings"
                      ? "group-hover:[&_path]:fill-primary"
                      : "group-hover:[&_path]:stroke-primary"
                  }`}
                />

                <div className="grow shrink basis-0 text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
                  {item.title}
                </div>
              </Link>
            ))}
          </div>

          <div className="self-stretch h-28 w-[215px] p-6 bg-white flex-col justify-between items-start gap-2.5 flex">
            <div className="flex-col justify-start items-start gap-2.5 flex">
              <div className="self-stretch px-4 py-3 bg-white rounded-lg border border-gray-200 justify-start items-center gap-2 inline-flex">
                <div className="w-9 h-9 rounded-full">
                  <img
                    src={user?.profilePic || userImage}
                    alt="profile-pic"
                    className="w-full h-full rounded-full object-fit"
                  />
                </div>
                <div className="flex-col gap-0.5 flex">
                  <span className="text-dark1 text-sm font-medium">
                    John smith
                  </span>
                  <span className="text-dark3 text-xs font-normal">Admin</span>
                </div>
              </div>
            </div>
            <button type="button" onClick={handleLogout}>
              <LogoutIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
