import { useState } from "react";
import DatePickerComponent from "react-date-picker";
import { ReactComponent as CalendarIcon } from "../../assets/calendar-2.svg";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

export function DatePicker({
  value,
  onChange,
}: {
  value: any;
  onChange: (value: any) => void;
}) {
  return (
    <div className="date-picker">
      <DatePickerComponent
        onChange={onChange}
        value={value}
        calendarIcon={<CalendarIcon />}
        format="MM/dd/yyyy"
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
        clearIcon={null}
        className="!outline-none w-full bg-white focus:bg-white rounded-lg border border-[#D9D9E7] focus-within:border-grey2 focus:border-grey2 p-3 text-base text-black [&>div]:!border-none"
      />
    </div>
  );
}
