import { Modal } from "../../../../../components/Modal";
import { useUser } from "../../../../profile/context/user-context";
import userImage from "../../../../../assets/user.png";
import { Button } from "../../../../../components/Button";
import { SelectInput } from "../../../../../components/SelectInput";
import { DateTimePicker } from "./DateTimePicker";
import { ReactComponent as ImageIcon } from "../../../../../assets/ic_image.svg";
import { ReactComponent as MagicWand } from "../../../../../assets/magic-wand.svg";
import { useRef, useState } from "react";
import { TagPeople } from "./TagPeople";
import { LocationInput } from "./LocationInput";
import { fileUpload } from "../../../../../services/file-upload";
import { getUser } from "../../../../../services/utils";
import { AiPostGenerator } from "../AiPostGenerator";

export function CreatePostForm({
  open,
  onClose,
  postForm,
  setPostForm,
  onSchedule,
  regeneratePost,
  onRefresh,
  hideAITool = false,
  saving,
  onDelete,
  userProfile,
}: {
  open: boolean;
  onClose: () => void;
  postForm: any;
  setPostForm: any;
  onSchedule: any;
  regeneratePost: any;
  onRefresh: any;
  hideAITool?: boolean;
  saving?: boolean;
  onDelete?: () => void;
  userProfile: any;
}) {
  const imageRef = useRef<HTMLInputElement>(null);
  const [showAiGenerator, setShowAiGenerator] = useState(false);
  const [profilePicLoading, setProfilePicLoading] = useState<boolean>(false);
  const [images, setImages] = useState<any>(null); // [file, setFile]
  const [usernames, setUsernames] = useState<any>(null); // [file, setFile

  const handleSchedule = () => {
    onSchedule(postForm);
  };

  const handleRegenerate = (form) => {
    regeneratePost(form);
  };

  const handleFileUpload = async (files: any) => {
    const file = files[0];
    setProfilePicLoading(true);
    const uploadResponse = await fileUpload(
      file,
      userProfile._id,
      "USER_THUMBNAIL"
    );
    if (uploadResponse.url) {
      setPostForm((form) => ({ ...form, imageUrl: uploadResponse.url }));
    }
    setProfilePicLoading(false);
  };

  const handleAutoPostGenerate = async () => {
    setShowAiGenerator(true);
  };

  const handleCloseAiGenerator = () => {
    setShowAiGenerator(false);
  };

  if (!open) return null;
  console.log(userProfile);
  return (
    <Modal contentClassName="lg:max-w-[940px]" open={open} onClose={onClose}>
      <h1 className="text-neutral-800 text-lg font-semibold mb-6 leading-normal">
        Schedule post
      </h1>
      <div className="flex justify-between items-center">
        <div className="self-stretch px-4 py-3 bg-white rounded-lg border border-primary justify-start items-center gap-2 inline-flex">
          <div className="w-9 h-9 rounded-full">
            <img
              src={
                userProfile?.instagramProfilePic ||
                userProfile?.onboardingSubmissions?.IMAGES?.[0] ||
                userImage
              }
              alt="profile-pic"
              className="w-full h-full rounded-full object-fit"
            />
          </div>
          <div className="flex-col gap-0.5 flex">
            <span className="text-dark1 text-sm font-medium">
              @{userProfile?.onboardingSubmissions?.INSTAGRAM_HANDLE?.username}
            </span>
            <span className="text-dark3 text-xs font-normal">Instagram</span>
          </div>
        </div>
        {!hideAITool && (
          <Button
            variant="outline"
            onClick={handleAutoPostGenerate}
            className="!px-4"
          >
            <MagicWand />
          </Button>
        )}
      </div>
      <div className="flex flex-wrap justify-center md:justify-start gap-8 border border-solid border-lightGray rounded-lg bg-white p-4 mt-6">
        <div className="w-[300px] h-[300px] bg-[#F6F6F6] rounded flex flex-col items-center justify-center gap-4">
          {postForm.imageUrl ? (
            <img
              src={postForm.imageUrl}
              alt=""
              className="w-full h-full object-cover rounded"
            />
          ) : (
            <>
              <ImageIcon />
              <span className="text-sm text-dark3">
                Choose a file or drag it here
              </span>
              <Button
                variant="outline"
                className="py-1.5 bg-white !border-lightGray"
                onClick={() => imageRef.current?.click()}
              >
                Upload
              </Button>
              <input
                type="file"
                className="hidden"
                ref={imageRef}
                accept="image/jpg, image/jpeg"
                onChange={(e) => {
                  console.log(e.target.files);
                  const files = e.target.files;
                  console.log(files);
                  if (files?.length) {
                    handleFileUpload(files);
                  }
                  e.target.value = "";
                }}
              />
            </>
          )}
        </div>
        <div className="flex-1">
          <textarea
            className="w-full h-32 rounded-lg border-none text-sm !outline-none p-4"
            placeholder="Write your caption..."
            value={postForm.caption}
            onChange={(e) =>
              setPostForm((form) => ({ ...form, caption: e.target.value }))
            }
          />
          <div className="rounded-lg border border-lightGray w-full my-4">
            <TagPeople
              value={postForm.tagPeople || []}
              onChange={(value) => {
                setPostForm((form) => ({ ...form, tagPeople: value }));
              }}
            />
            <div className="border-b border-lightGray" />
            <LocationInput
              value={postForm.location}
              onChange={(e) =>
                setPostForm((form) => ({ ...form, location: e.target.value }))
              }
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap flex-col-reverse md:flex-row justify-between items-center mt-6">
        <div className="hidden md:block">
          <Button variant="outline" onClick={onClose}>
            Discard post
          </Button>
        </div>
        <div className="flex flex-wrap md:flex-nowrap gap-5 items-center">
          <SelectInput
            data={[
              { label: "Call-to-Action (CTA)", value: "CALL_TO_ACTION" },
              { label: "Informational", value: "INFORMATIONAL" },
              { label: "Testimonials", value: "TESTIMONIALS" },
              { label: "Personal Post", value: "PERSONAL_POST" },
              { label: "Share your Story", value: "SHARE_YOUR_STORY" },
            ]}
            value={postForm.type}
            onChange={(e: any) => {
              setPostForm((form) => ({ ...form, type: e.target.value }));
              // handleRegenerate({...postForm, type: e.target.value})
            }}
            className="!py-2 !bg-white !rounded"
          />
          <DateTimePicker
            onChange={(date: any) =>
              setPostForm((form) => ({ ...form, scheduleDateTime: date }))
            }
            value={postForm.scheduleDateTime}
          />
          <div className="flex items-center justify-between gap-4 md:gap-0 md:justify-end w-full md:w-auto">
            <Button
              variant="primary"
              className="flex-1"
              loading={saving}
              onClick={handleSchedule}
            >
              Schedule
            </Button>
            <Button
              variant="outline"
              className="md:hidden flex-1"
              onClick={onClose}
            >
              Discard post
            </Button>
          </div>
        </div>
      </div>
      {showAiGenerator && (
        <AiPostGenerator
          onClose={handleCloseAiGenerator}
          open={showAiGenerator}
          onRefresh={onRefresh}
        />
      )}
    </Modal>
  );
}
