import React, { useEffect } from "react";
import { api } from "../../../services/api";
import { getUser } from "../../../services/utils";

export const UserContext = React.createContext({});

export function UserProvider({ children }) {
    const [userInfo, setUserInfo] = React.useState({});
    const user = getUser();
    const fetchUser = async () => {
        const response = await api.getUserProfile(user._id);
        setUserInfo({
            ...response.onboardingSubmissions,
            ...response,
        });
    }
    const values = {
        userInfo,
        setUserInfo,
        fetchUser,
    }
    useEffect(() => {
        fetchUser();
    }, []);
  return (
    <UserContext.Provider value={values}>{children}</UserContext.Provider>
  );
}

export function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}