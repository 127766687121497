import React, { useEffect, useState } from "react";
import WebFont from "webfontloader";
import fonts from "google-fonts-complete";

import { BrandStyleTab } from "./BrandStyleTab";
import { Modal } from "../../../components/Modal";
import { Button } from "../../../components/Button";
import { SelectInput } from "../../../components/SelectInput";
import { TextInput } from "../../../components/TextInput";

const fontWeightsTitle = [
  {
    name: "Thin",
    weight: 100,
  },
  {
    name: "Extra-light",
    weight: 200,
  },
  {
    name: "Light",
    weight: 300,
  },
  {
    name: "Regular",
    weight: 400,
  },
  {
    name: "Medium",
    weight: 500,
  },
  {
    name: "Semi-bold",
    weight: 600,
  },
  {
    name: "Bold",
    weight: 700,
  },
  {
    name: "Extra-bold",
    weight: 800,
  },
  {
    name: "Black",
    weight: 900,
  },
];

const GoogleFontSelector = React.memo(({ font, onChange }: any) => {
  const loadFont = (font) => {
    WebFont.load({
      google: {
        families: [font],
      },
    });
  };

  const handleFontChange = (event) => {
    onChange(event.target.value);
    loadFont(event.target.value);
  };

  return (
    <div style={{ fontFamily: font }}>
      <SelectInput
        value={font}
        onChange={handleFontChange}
        data={Object.keys(fonts).map((fontName) => ({
          label: fontName,
          value: fontName,
        }))}
      />
    </div>
  );
});

export function BrandStylesFont({
  fonts,
  onUpdate,
}: {
  fonts: any;
  onUpdate: (data: any) => any;
}) {
  const [edit, setEdit] = React.useState<boolean>(false);
  const [editFonts, setEditFonts] = React.useState<any>({});
  const [saving, setSaving] = React.useState<boolean>(false);

  const handleEdit = () => {
    setEdit(true);
    setEditFonts(fonts);
  };

  const handleClose = () => {
    setEdit(false);
  };

  const handleUpdate = async () => {
    setSaving(true);
    const object = {
      h1: {
        ...editFonts.h1,
        "font-size": editFonts.h1["font-size"].includes("px") ? editFonts.h1["font-size"] : `${editFonts.h1["font-size"]}px`,
      },
      h2: {
        ...editFonts.h2,
        "font-size": editFonts.h2["font-size"].includes("px") ? editFonts.h2["font-size"] : `${editFonts.h2["font-size"]}px`,
      },
    };
    const response = await onUpdate({
      brandStyleTypography: object,
    });
    setSaving(false);
    if (response) {
      setEdit(false);
    }
  };

  useEffect(() => {
    // load fonts on font change
    if(fonts?.h1?.["font-family"]) {
      WebFont.load({
        google: {
          families: [fonts?.h1?.["font-family"]],
        },
      });
    }
    if(fonts?.h2?.["font-family"]) {
      WebFont.load({
        google: {
          families: [fonts?.h2?.["font-family"]],
        },
      });
    }
  }
  , [fonts]);

  return (
    <div>
      <BrandStyleTab label="Fonts" onEdit={handleEdit} />
      <div className="flex flex-col md:flex-row justify-between flex-wrap gap-8 mt-4">
        <div className="flex-1">
          <h3 className="text-neutral-800 text-sm font-normal leading-normal">
            Use for H1, headline, titles, quote
          </h3>
          <h2
            className="text-[40px] my-6"
            style={{
              fontFamily: fonts?.h1?.["font-family"],
              fontSize: fonts?.h1?.["font-size"]
            }}
          >
            {fonts?.h1?.["font-family"]}
          </h2>
          <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 gap-5 flex-wrap">
            {fontWeightsTitle.map((weight) => (
              <div
                key={weight.name}
                className="text-xl font-inter font-semibold"
                style={{
                  fontWeight: weight.weight,
                  fontFamily: fonts?.h1?.["font-family"]
                }}
              >
                {weight.name}
              </div>
            ))}
          </div>
        </div>
        <div className="flex-1">
          <h3 className="text-neutral-800 text-sm font-normal leading-normal">
            Use for H2, H3, sub-headline, body text
          </h3>
          <h2
            className="text-[40px] my-6"
            style={{
              fontFamily: fonts?.h2?.["font-family"],
              fontSize: fonts?.h2?.["font-size"]
            }}
          >
            {fonts?.h2?.["font-family"]}
          </h2>
          <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 gap-5 flex-wrap">
            {fontWeightsTitle.map((weight) => (
              <div
                key={weight.name}
                className="text-xl font-inter font-semibold"
                style={{
                  fontWeight: weight.weight,
                  fontFamily: fonts?.h2?.["font-family"]
                }}
              >
                {weight.name}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal
        contentClassName="max-w-[525px] !mx-2"
        open={edit}
        onClose={handleClose}
      >
        <div>
          <div className="text-neutral-800 text-2xl font-semibold">
            Edit Colors
          </div>
          <div className="flex gap-8 flex-wrap mt-4 flex-col">
            <div>
              <h3 className="text-neutral-800 text-sm font-normal leading-normal mb-3">
                Use for H1, headline, titles, quote
              </h3>
              <GoogleFontSelector
                font={editFonts?.h1?.["font-family"]}
                onChange={(font) => {
                  setEditFonts({
                    ...editFonts,
                    h1: { ...editFonts.h1, "font-family": font },
                  });
                }}
              />
              <div className="mt-4 flex gap-5 items-center">
                <SelectInput
                  value={editFonts?.h1?.["font-style"]}
                  onChange={(e) => {
                    setEditFonts((prev) => ({
                      ...prev,
                      h1: { ...prev.h1, "font-style": e.target.value },
                    }));
                  }}
                  data={[
                    { label: "Normal", value: "normal" },
                    { label: "Italic", value: "italic" },
                  ]}
                />
                <TextInput
                  type="number"
                  value={editFonts?.h1?.["font-size"].replace("px", "")}
                  onChange={(e) => {
                    setEditFonts({
                      ...editFonts,
                      h1: { ...editFonts.h1, "font-size": e.target.value },
                    });
                  }}
                />
              </div>
            </div>
            <div>
              <h3 className="text-neutral-800 text-sm font-normal leading-normal mb-3">
                Use for H2, H3, sub-headline, body text
              </h3>
              <GoogleFontSelector
                font={editFonts?.h2?.["font-family"]}
                onChange={(font) => {
                  setEditFonts({
                    ...editFonts,
                    h2: { ...editFonts.h2, "font-family": font },
                  });
                }}
              />
              {/** font style select input */}
              <div className="mt-4 flex gap-5 items-center">
                <SelectInput
                  value={editFonts?.h2?.["font-style"]}
                  onChange={(e) => {
                    setEditFonts({
                      ...editFonts,
                      h2: { ...editFonts.h2, "font-style": e.target.value },
                    });
                  }}
                  data={[
                    { label: "Normal", value: "normal" },
                    { label: "Italic", value: "italic" },
                  ]}
                />
                <TextInput
                  type="number"
                  value={editFonts?.h2?.["font-size"].replace("px", "")}
                  onChange={(e) => {
                    setEditFonts({
                      ...editFonts,
                      h2: { ...editFonts.h2, "font-size": e.target.value },
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="mt-8 flex justify-end">
            <Button variant="primary" onClick={handleUpdate} loading={saving}>
              Update
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
