import { Button } from "../../../../components/Button";
import { Modal } from "../../../../components/Modal";
import { TextInput } from "../../../../components/TextInput";
import { ReactComponent as CheckIcon } from "../../../../assets/check.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/x.svg";
import { ReactComponent as UploadIcon } from "../../../../assets/upload.svg";
import { useEffect, useState } from "react";
import React from "react";
import { Loading } from "../../../../components/Loading/Loading";
import { fileUpload } from "../../../../services/file-upload";
import { set } from "date-fns";
import { api } from "../../../../services/api";
import toast from "react-hot-toast";

export function EditProfileModal({
  open,
  onClose,
  user,
  refetch,
  userId,
}: {
  open: boolean;
  onClose: () => void;
  user?: any;
  refetch: () => void;
  userId: string;
}) {
  const [form, setForm] = useState<any>({});
  const [file, setFile] = useState<File | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const profilePicRef = React.useRef<HTMLInputElement>(null);

  const handleSave = async () => {
    // const uploadResponse = await fileUpload(file, userId, "USER_THUMBNAIL");
    // profilePic: uploadResponse.url,
    setSaving(true);
    const data = {
      onboardingSubmissions: {
        FIRST_NAME: form.firstName,
        LAST_NAME: form.lastName,
      },
      email: form.email,
      profilePic: form.profilePic,
    };
    if (file) {
      const uploadResponse = await fileUpload(file, userId, "USER_THUMBNAIL");
      data.profilePic = uploadResponse.url;
    }
    api
      .saveUserProfile(userId, {
        ...data,
      })
      .then((response) => {
        console.log(response);
        toast.success("User updated successfully");
        refetch();
        onClose();
      })
      .catch((err) => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleProfilePicChange = async (file: File) => {
    setFile(file);
  };

  useEffect(() => {
    if (!user) return;
    setForm({
      firstName: user?.onboardingSubmissions?.FIRST_NAME,
      lastName: user?.onboardingSubmissions?.LAST_NAME,
      email: user?.email,
      profilePic: user?.profilePic,
    });
  }, [user]);
  console.log("form", form);

  const imageURL = file ? URL.createObjectURL(file) : form.profilePic;

  return (
    <Modal
      open={open}
      onClose={onClose}
      showCloseButton={false}
      contentClassName="!p-8 max-w-[532px]"
    >
      <div className="mb-6 flex items-start justify-between">
        <h1 className="text-dark1 text-[24px] font-semibold">
          Edit user details
        </h1>
        <button onClick={onClose} aria-label="Close" className="pt-2">
          <CloseIcon className="w-6 h-6 [&_path]:stroke-black" />
        </button>
      </div>
      <div className="flex flex-col gap-6">
        <div>
          <input
            type="file"
            className="hidden"
            ref={profilePicRef}
            accept="image/*"
            onChange={(e) => {
              const files = e.target.files;
              if (files?.length) {
                handleProfilePicChange(files[0]);
              }
              e.target.value = "";
            }}
          />
          <button
            onClick={() => profilePicRef.current?.click()}
            className="relative w-[107px] h-[107px] rounded-full group"
          >
            <img
              src={imageURL}
              alt="profile-pic"
              className="w-full h-full rounded-full object-cover"
            />
            <div className="hidden group-hover:flex justify-center items-center top-0 left-0 rounded-full absolute bg-black/[0.27] bottom-0 right-0">
              <UploadIcon />
            </div>
          </button>
        </div>
        <div className="flex flex-col gap-2">
          <label>First name</label>
          <TextInput
            value={form.firstName}
            onChange={(e) => setForm({ ...form, firstName: e.target.value })}
          />
        </div>

        <div className="flex flex-col gap-2">
          <label>Last name</label>
          <TextInput
            value={form.lastName}
            onChange={(e) => setForm({ ...form, lastName: e.target.value })}
          />
        </div>

        <div className="flex flex-col gap-2">
          <label>Email</label>
          <TextInput
            value={form.email}
            onChange={(e) => setForm({ ...form, email: e.target.value })}
          />
        </div>
      </div>
      <div className="flex justify-end items-center gap-2.5 mt-6">
        <Button
          variant="primary"
          disabled={saving || !form.firstName || !form.lastName || !form.email}
          onClick={handleSave}
        >
          {!saving && (
            <CheckIcon className="w-4 h-4 mt-[2px] [&_path]:stroke-black" />
          )}
          <span>Save</span>
          {saving && <Loading />}
        </Button>
      </div>
    </Modal>
  );
}
