import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { Fragment, useEffect, useState } from "react";
import { Button } from "../../../../components/Button";
import { CreatePostForm } from "./components/CreatePostModal";
import { ReactComponent as RightIcon } from "../../../../assets/right-chevron.svg";
import { ReactComponent as FilterListIcon } from "../../../../assets/ic_filter_list.svg";
import { getUser } from "../../../../services/utils";
import { api } from "../../../../services/api";
import moment from "moment";
import { AiPostGenerator } from "./AiPostGenerator";
import { Menu, Transition } from "@headlessui/react";

const locales = {
  "en-US": enUS,
};

const scheduleTypes = [
  {
    name: "TESTIMONIALS",
    label: "Testimonials",
    colorClass: "bg-[#EE826B]",
  },
  {
    name: "INFORMATIONAL",
    label: "Informational",
    colorClass: "bg-[#688692]",
  },
  {
    name: "CALL_TO_ACTION",
    label: "Call-to-Action (CTA)",
    colorClass: "bg-[#B7D7E4]",
  },
  {
    name: "PERSONAL_POST",
    label: "Personal Post",
    colorClass: "bg-[#C1AD3F]",
  },
  {
    name: "SHARE_YOUR_STORY",
    label: "Share your Story",
    colorClass: "bg-[#E4C270]",
  },
];

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const CustomToolbar =
  ({ onCreate, onAutoGenerate, generatingPost }: any) =>
  (toolbar) => {
    console.log("other", toolbar);
    const goToBack = () => {
      // convert by view [week, month]
      if (toolbar.view === "week") {
        toolbar.date.setDate(toolbar.date.getDate() - 7);
      } else {
        toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      }
      toolbar.onNavigate("prev");
    };

    const goToNext = () => {
      if (toolbar.view === "week") {
        toolbar.date.setDate(toolbar.date.getDate() + 7);
      } else {
        toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      }
      toolbar.onNavigate("next");
    };

    const goToCurrent = () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.onNavigate("current");
    };

    // const label = () => {
    //   const date = moment(toolbar.date);
    //   return (
    //     <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
    //   );
    // };

    return (
      <div className="flex justify-between items-center mb-6 flex-wrap gap-4">
        <div className="flex justify-between items-center gap-6">
          <div className="flex flex-col">
            <div className="text-neutral-800 text-lg font-semibold font-['Inter'] leading-normal">
              {toolbar.localizer.format(
                toolbar.date,
                toolbar.localizer.formats.monthHeaderFormat
              )}
            </div>
            <div className="text-zinc-500 text-xs font-normal font-['Inter']">
              Today
            </div>
          </div>
          <div className="flex items-center gap-3">
            <button
              className="border rounded border-solid border-lightGray h-10 w-10 flex justify-center items-center"
              onClick={goToBack}
            >
              <RightIcon className="rotate-180" />
            </button>
            <button
              className="border rounded border-solid border-lightGray h-10 w-10 flex justify-center items-center"
              onClick={goToNext}
            >
              <RightIcon />
            </button>
          </div>
        </div>
        <div className="flex justify-between items-center gap-8 flex-wrap">
          <Button
            variant="primary"
            className="!text-sm !font-normal !py-2 flex items-center"
            onClick={onCreate}
          >
            <div className="!text-xl mb-[2px]">+</div> Create
          </Button>
          <div className="border-lightGray border rounded flex w-fit">
            {toolbar.views.map((view, index) => (
              <button
                key={view}
                type="button"
                onClick={() => toolbar.onView(view)}
                className={`py-3 px-4 text-sm font-normal capitalize ${
                  index < toolbar.view.length - 2
                    ? "border-r border-solid border-lightGray"
                    : ""
                } ${toolbar.view === view ? "text-primary" : "text-dark3"}`}
              >
                {view}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  };

const CustomEvent = ({ event }) => {
  console.log(event);
  const typeClassName =
    scheduleTypes.find((item) => item.name === event.resource.type)
      ?.colorClass || "bg-[#EE826B]";
  return (
    <div
      role="button"
      onClick={event.resource.onClick}
      className="flex flex-col items-center gap-[2px]"
    >
      <div className="flex justify-between items-center w-full px-[2px]">
        <span className="text-xs text-dark3">
          {moment(event.start).format("HH:mm")}
        </span>
        <div className={`h-1 w-1 rounded-[1px] ${typeClassName}`} />
      </div>
      <div className="w-full h-[57px]">
        <img
          src={event.resource.image}
          alt=""
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  );
};

const CustomEventContainer = ({ children, event }) => {
  return (
    <div className="bg-white rounded-lg shadow-[0px_1px_3px_0px_rgba(0,0,0,0.08)] p-4">
      {children}
    </div>
  );
};

const MyCalendar = (props) => (
  <div>
    <Calendar
      localizer={localizer}
      events={[
        ...props.posts.map((post) => ({
          start: new Date(post.scheduleDateTime),
          end: new Date(post.scheduleDateTime),
          resource: {
            image: post.imageUrl,
            type: post.type,
            onClick: () => props.onPostView(post),
          },
        })),
      ]}
      startAccessor="start"
      endAccessor="end"
      style={{ height: "60vh" }}
      views={["week", "month"]}
      defaultView="month"
      components={{
        toolbar: CustomToolbar({
          onCreate: props.onCreatePost,
          generatingPost: props.generatingPost,
          onAutoGenerate: props.onAutoGenerate,
        }),
        eventContainer: CustomEventContainer,
      }}
      formats={{
        timeGutterFormat: (date, culture, localizer) =>
          localizer.format(date, "HH:mm", culture),
        dayFormat: (date, culture, localizer) =>
          localizer.format(date, "EEE dd", culture),
      }}
      titleAccessor={(event) => <CustomEvent event={event} />}
      step={15}
      className="[&_.rbc-time-slot]:min-h-[20px] [&_.rbc-time-slot]:max-h-[20px] [&_.rbc-event]:!h-auto [&_.rbc-event]:!bg-white [&_.rbc-event]:!border-[#0AA630] [&_.rbc-event]:!p-[2px] [&_.rbc-event-label]:hidden"
    />
  </div>
);

export function Schedule({ userId, userProfile }) {
  const [showAiGenerator, setShowAiGenerator] = useState(false);
  const [generatingPost, setGeneratingPost] = useState(false);
  const [postDialog, setPostDialog] = useState(false);
  const [postForm, setPostForm] = useState<any>({});
  const [posts, setPosts] = useState<any[]>([]);
  const [saving, setSaving] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  const [unChangedPostForm, setUnChangedPostForm] = useState<any>({});

  const handleCreatePost = () => {
    setPostDialog(true);
    setPostForm({
      type: "CALL_TO_ACTION",
    });
  };

  const handleFilter = (filterValue: string | null) => {
    console.log("filter");
    setSelectedFilter(filterValue);
  };

  const discardOldPost = (id: string) => {
    setPosts((prev) => prev.filter((e) => e._id !== id));
  };

  const filterPostByType = (data, type) => {
    if (!type) {
      return data || [];
    }
    return data.filter((item) => item.type === type);
  };

  const fetchPosts = async (userId) => {
    const res = await api.getPosts(userId);
    console.log(res);
    setPosts([...res]);
  };

  const handleAutoPostGenerate = async () => {
    setShowAiGenerator(true);
    // setGeneratingPost(true);
    // const result = await api.createPost({
    //   userId: userId,
    //   creationMode: "AUTO",
    //   type: "SHARE_YOUR_STORY",
    // });
    // const res = await api.getPosts(userId);
    // setPosts([...res]);
    // setGeneratingPost(false);
  };

  const handleCloseAiGenerator = () => {
    setShowAiGenerator(false);
  };

  const handleSchedule = (data: any) => {
    console.log(data);
    if (data._id) {
      // update
      setSaving(true);
      api.updatePost(data._id, data).then(async (res) => {
        console.log(res);
        await fetchPosts(userId);
        setPostDialog(false);
        setSaving(false);
      });
    } else {
      // create
      const obj = {
        creationMode: "MANUAL",
        userId: userId,
        type: data.type,
        imageUrl: data.imageUrl,
        tags: data.tags,
        location: data.location,
        caption: data.caption,
        scheduleDateTime: data.scheduleDateTime,
      };
      setSaving(true);
      api.createPost(obj).then(async (res) => {
        console.log(res);
        await fetchPosts(userId);
        setPostDialog(false);
        setSaving(false);
      });
    }
    // api.schedulePost(userId, postForm._id, data).then((res) => {
    //   console.log(res);
    //   setPostDialog(false);
    // });
  };

  const regeneratePost = (form) => {
    api.updatePost(form._id, { ...form }).then((res) => {
      console.log(res);
      // setPostForm(res.data);
    });
  };

  const handleViewPost = (post: any) => {
    console.log(post);
    setPostDialog(true);
    setPostForm(post);
    setUnChangedPostForm(post);
  };

  const createPosts = async () => {
    for (let i = 0; i < 5; i++) {
      const types = [
        "CALL_TO_ACTION",
        "INFORMATIONAL",
        "PERSONAL_POST",
        "SHARE_YOUR_STORY",
        "TESTIMONIALS",
      ];
      for (let j = 0; j < types.length; j++) {
        await api.createPost({
          userId: userId,
          creationMode: "AUTO",
          type: types[j],
        });
      }
    }
  };

  useEffect(() => {
    if (userId) {
      fetchPosts(userId);
    }
  }, [userId]);
  console.log(userProfile);
  return (
    <div>
      <div className="rounded-lg shadow-[0px_1px_3px_0px_rgba(0,0,0,0.08)] p-6 bg-white">
        <div className="flex flex-col md:flex-row gap-2 md:gap-0">
          <div className="flex-1">
            <MyCalendar
              onCreatePost={handleCreatePost}
              posts={posts}
              generatingPost={generatingPost}
              onAutoGenerate={handleAutoPostGenerate}
              onPostView={handleViewPost}
            />
          </div>
          <div className="w-[300px] pb-6 pl-5 ml-1 pt-3 border border-lightGray">
            <div className="pb-5 pr-6 flex justify-between items-center">
              <h3 className="text-sm text-dark1">Upcoming Posts</h3>
              {/* <button className="flex gap-1 items-center">
                <span className="text-dark3 text-sm">Filter</span>
                <FilterListIcon />
              </button> */}
              <Menu>
                <Menu.Button>
                  <div className="flex gap-1 items-center">
                    <span className="text-dark3 text-sm">
                      {selectedFilter
                        ? scheduleTypes.find((i) => i.name === selectedFilter)
                            ?.label
                        : "Filter"}
                    </span>
                    <FilterListIcon />
                  </div>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute shadow mt-[100px] right-6 bg-white rounded-md z-[9999] py-2 min-w-[150px]">
                    <Menu.Item>
                      <button
                        onClick={() => handleFilter(null)}
                        className="flex items-center gap-2 px-6 py-2 w-full text-left hover:bg-black/5"
                      >
                        <div className="text-sm text-dark3 font-medium">
                          All
                        </div>
                      </button>
                    </Menu.Item>
                    {scheduleTypes.map((item, index) => (
                      <Menu.Item>
                        <button
                          onClick={() => handleFilter(item.name)}
                          className="flex items-center gap-2 px-6 py-2 w-full text-left hover:bg-black/5"
                        >
                          <div
                            className={`w-4 h-4 rounded ${item.colorClass}`}
                          ></div>
                          <div className="text-sm text-dark3 font-medium">
                            {item.label}
                          </div>
                        </button>
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="flex flex-wrap gap-1.5 max-h-[48vh] overflow-auto">
              {posts.map((post, index) => (
                <button key={index} onClick={() => handleViewPost(post)}>
                  <div className="w-20 h-20" key={index}>
                    <img
                      src={post.imageUrl}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-center gap-5 mt-6">
          {scheduleTypes.map((item, index) => (
            <div key={index} className="flex items-center gap-2">
              <div className={`w-4 h-4 rounded ${item.colorClass}`}></div>
              <div className="text-sm text-dark3 font-medium">{item.label}</div>
            </div>
          ))}
        </div>
      </div>
      <CreatePostForm
        open={postDialog}
        onClose={() => setPostDialog(false)}
        postForm={postForm}
        userProfile={userProfile}
        setPostForm={setPostForm}
        onSchedule={handleSchedule}
        regeneratePost={regeneratePost}
        onDelete={() => {
          discardOldPost(postForm._id);
          setPostForm({});
          setUnChangedPostForm({});
        }}
        onRefresh={() => {
          setPostDialog(false);
          setPostForm({});
          fetchPosts(userId);
          setUnChangedPostForm({});
        }}
        saving={saving}
      />
      {showAiGenerator && (
        <AiPostGenerator
          onClose={handleCloseAiGenerator}
          open={showAiGenerator}
          onRefresh={() => fetchPosts(userId)}
        />
      )}
    </div>
  );
}
