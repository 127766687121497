import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../../../../assets/arrow-left.svg";
import { ReactComponent as RightIcon } from "../../../../assets/chevron-left.svg";

export function Breadcrumb({ name }: { name?: string }) {
  return (
    <div className="flex items-center gap-[14px]">
      <Link to="/users" className="flex items-center gap-1">
        <LeftIcon className="[&_path]:stroke-primary" />
        <span className="text-[#151E21] text-base">Back</span>
      </Link>
      <div className="h-[10px] w-[1px] bg-black/40" />
      <div className="flex items-center gap-1">
        <Link to="/users">
          <span className="text-[#8D8E92] text-base">Manage users</span>
        </Link>
        <RightIcon className="rotate-180 [&_path]:stroke-[#84818A]" />
        <span className="text-[#8D8E92] text-base">{name}</span>
      </div>
    </div>
  );
}
