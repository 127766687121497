export function HighlightCard({
  icon,
  label,
  value,
  onClick,
  progressRate,
  progressColor,
  className="",
}: {
  icon: any;
  label: string;
  value: string;
  onClick: any;
  progressRate?: string;
  progressColor?: string;
  className?: string;
}) {
  return (
    <div className={`relative w-[260px] gap-12 items-center px-6 py-5 bg-white rounded-lg shadow flex ${className}`}>
        <div className="flex flex-col gap-1">
            <div className="flex justify-between items-center gap-3">
                <div className="w-6 h-6 relative">
                    {icon}
                </div>
                <div className="text-neutral-800 text-sm font-medium font-['Inter']">
                    {label}
                </div>
            </div>
            <div className="text-neutral-800 pl-10 text-2xl font-bold font-['Inter']">
                {value}
            </div>
        </div>
      {progressColor && <div className={`text-right ${progressColor} text-sm font-medium font-['Inter']`}>
        {progressRate}
      </div>}
    </div>
  );
}
