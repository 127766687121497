import { useEffect, useState } from "react";
import { ReactComponent as LocationIcon } from "../../../../../assets/ic_place.svg";
import { useDebounce } from "../../../../../services/useDebounce";
import instagramAPI from "../../../../../services/instagram";
import axios from "axios";
import { FACEBOOK_APP_ID, FACEBOOK_APP_SECRET } from "../../../../../constant";

const token = "EAAN7KeHYt7ABO9MOmfrHjr7IqZBNVH7EqhgeUDyk80rqgPTRzeBdTmTxtnU0zyiVpc8KjaxitC7wMyPhiZBMAcXMSKt3D797kv6LteHSdTFalDZATWn7N2ZBlMsiZAGG3qEqOYOf3NM6K5doPZA4z7WlpbHZAFMr38WVMzdNOHr7BHWibHAEtWhGVaf2ePnb3pwZABbb6JsMzwAZD"
export function LocationInput({value, onChange}) {
    const [search, setSearch] = useState("");
    const searchQuery = useDebounce(search, 2000)

    useEffect(() => {
        if(searchQuery) {
            axios.get(`https://graph.facebook.com/oauth/access_token?client_id=${FACEBOOK_APP_ID}&client_secret=${FACEBOOK_APP_SECRET}&grant_type=client_credentials`).then(res => {
                console.log(res.data);
                instagramAPI.searchLocation(token, searchQuery).then((res) => console.log("looooo", res));
            })
        }
    }, [searchQuery]);


    return (
        <div className="px-4 py-3 flex gap-3 items-center">
            <LocationIcon />
            <div>
                <input type="text" placeholder="Add Location" className="text-sm w-full border-none !outline-none" 
                    value={value} onChange={(e) => setSearch(e.target.value)}
                />
            </div>
        </div>
    )
}