import { useEffect, useState } from "react";
import { PostGeneratorStep1 } from "./components/PostGeneratorStep1";
import { PostGeneratorStep2 } from "./components/PostGeneratorStep2";
import { Modal } from "../../../../components/Modal";
import Logo from "../../../../assets/Logo.svg";
import { getUser } from "../../../../services/utils";
import { api } from "../../../../services/api";
import { ShowToast } from "../../../../services/toast";

const steps: { [key: number]: any } = {
  1: PostGeneratorStep1,
  2: PostGeneratorStep2,
};

const stepsCount = Object.keys(steps).length;

export function AiPostGenerator({ onClose, open, onRefresh }: { onClose: () => void; open: boolean; onRefresh: () => Promise<void> }) {
  const [step, setStep] = useState<number>(0);
  const [data, setData] = useState<any>({type: 'CALL_TO_ACTION'});
  const [saving, setSaving] = useState<boolean>(false);
  const user = getUser();


  const handleBack = () => {
    if (step === 0) return;
    setStep(step - 1);
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      console.log(data);
      const result = await api.createPost({
        userId: user._id,
        creationMode: "AUTO",
        ...data,
      });
      console.log(result);
      await onRefresh();
      setSaving(false);
      onClose();
    } catch (error: any) {
      ShowToast({message: "Something went wrong, please try again later", type: "error"} as any);
    } finally {
      setSaving(false);
    }
  };

  const handleNext = () => {
    
    if (step === stepsCount - 1) {
        handleSave();
    } else {
        setStep(step + 1);
    }
  };

  const handleChange = (data: any) => {
    setData((prev) => ({ ...prev, ...data }));
  }

  const handleClose = async () => {
    setStep(1);
    setData({type: 'CALL_TO_ACTION'});
    onClose();
  };
  const Step = steps[step + 1];

  return (
    <Modal contentClassName="px-0 md:max-w-[700px] lg:max-w-[800px]" onClose={handleClose} open={open}>
    <div className="border-b border-[#DFDFDF] pb-6">
        <div className="max-w-[700px] w-full mx-auto px-5">
            <div className="w-[133px] h-[37px] mb-5">
                <img src={Logo} alt="logo" className="w-full h-full object-cover" />
            </div>
        </div>
    </div>
    <div className="relative flex-1 flex max-w-[700px] min-h-[400px] px-5 mx-auto w-full flex-col items-center">
        {step <= stepsCount && (
            <Step onBack={handleBack} onNext={handleNext} data={data} onChange={handleChange} saving={saving} />
        )}
    </div>
    </Modal>
  );
}
