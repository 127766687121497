import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as DashboardIcon } from "../../assets/dashboard.svg";
import { ReactComponent as Logo } from "../../assets/Logo.svg";
import { ReactComponent as LogoutIcon } from "../../assets/logout.svg";
import { ReactComponent as GuidelyIcon } from "../../assets/guidely-icon.svg";
import { ReactComponent as ProgressIcon } from "../../assets/progress.svg";
import { ReactComponent as UserIcon } from "../../assets/user.svg";
import { ReactComponent as AdminUserIcon } from "../../assets/icon-admin.svg";
import { ReactComponent as PhotoLibraryIcon } from "../../assets/photo-library.svg";
import { ReactComponent as SupportIcon } from "../../assets/support.svg";
import { ReactComponent as SettingsIcon } from "../../assets/settings.svg";
import userImage from "../../assets/user.png";

import { ReactComponent as LibraryIcon } from "../../assets/local-library.svg";
import { ReactComponent as LiveHelpIcon } from "../../assets/live-help.svg";
import { ReactComponent as ChevronLeftIcon } from "../../assets/chevron-left.svg";
import { getUser } from "../../services/utils";
import { AdminSignup } from "../../pages/auth";
import { ShowToast } from "../../services/toast";
import { api } from "../../services/api";
import toast from "react-hot-toast";

const PROFILE_PIC =
  "https://scontent.fstv5-1.fna.fbcdn.net/v/t51.2885-15/393470545_363262946050114_2699725582897640060_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=7d201b&_nc_ohc=QnvOCbmlI1cAX-CBKHA&_nc_ht=scontent.fstv5-1.fna&edm=AL-3X8kEAAAA&oh=00_AfCJRu0jxavCkUKHt15MrG7gOlBxmY2yn1JIT-6gkSwJkQ&oe=653AE3BE";

export const menuItems = [
  {
    title: "Dashboard",
    Icon: DashboardIcon,
    path: "/dashboard",
  },
  {
    title: "Manage Users",
    Icon: UserIcon,
    path: "/users",
  },
];

export const helpMenuItems = [
  {
    title: "Knowledge Base",
    Icon: LibraryIcon,
    path: "/knowledge-base",
  },
  {
    title: "Help",
    Icon: LiveHelpIcon,
    path: "/help",
  },
];

export function Sidebar({ user }: { user: any }) {
  const location = useLocation();
  const adminUser = getUser();
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const openSignupModal = () => {
    setShowSignupModal(true);
  };

  const closeSignupModal = () => {
    setShowSignupModal(false);
  };

  const isSelected = (path) => {
    if (location.pathname.includes(path)) {
      return true;
    }
    return false;
  };
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };
  console.log(adminUser);
  const generateCsv = async () => {
    if (!adminUser?._id) return;
    try {
      toast.loading("Generating CSV...");
      const response = await api.generateCsv(adminUser._id);
      toast.dismiss();
      if ([200, 201].includes(response.status)) {
        // download the file
        const url = response?.data?.url;
        const fileName = url?.split("/").pop();
        if (url) {
          const link = document.createElement("a");
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        ShowToast({
          type: "success",
          message: "CSV generated successfully",
        });
      }
    } catch (error: any) {
      console.error(error);
      toast.dismiss();
      ShowToast({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  return (
    <div className="max-h-[100vh] overflow-auto bg-white border-r border-gray-200 flex-col">
      <div className="self-stretch pl-6 pr-2 py-5 bg-white border-b border-gray-200 justify-between items-center flex">
        <div className="justify-center items-center flex">
          <Logo />
        </div>
      </div>
      <div className="flex-col justify-between gap-5 flex h-[calc(100vh-70px)]">
        <div className="flex-col justify-start items-start flex w-full">
          {menuItems.map((item, index) => (
            <Link
              to={item.path}
              key={index}
              className={`w-full px-6 py-3.5 bg-white justify-start items-center gap-3 flex group hover:bg-primaryLight transition duration-75 hover:scale-105 ${
                isSelected(item.path) ? "!bg-[#F4EFE5]" : ""
              }`}
            >
              <item.Icon
                className={`group-hover:[&_circle]:stroke-primary ${
                  isSelected(item.path)
                    ? "[&_circle]:!stroke-primaryLight [&_path]:!stroke-primary"
                    : ""
                } ${
                  item.path === "/settings"
                    ? "group-hover:[&_path]:fill-primary"
                    : "group-hover:[&_path]:stroke-primary"
                }`}
              />

              {!collapsed && (
                <div className="grow shrink basis-0 text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
                  {item.title}
                </div>
              )}
            </Link>
          ))}

          <button
            onClick={openSignupModal}
            className="w-full px-6 py-3.5 bg-white justify-start items-center gap-3 flex group hover:bg-primaryLight transition duration-75 hover:scale-105"
          >
            <AdminUserIcon className={`group-hover:!fill-primary !w-6 !h-6`} />
            <span className="text-neutral-800 text-base font-medium">
              Add New Admin
            </span>
          </button>
          <button
            onClick={generateCsv}
            className="w-full px-6 py-3.5 bg-white justify-start items-center gap-3 flex group hover:bg-primaryLight transition duration-75 hover:scale-105"
          >
            <ProgressIcon
              className={`group-hover:[&_path]:!stroke-primary !w-6 !h-6`}
            />
            <span className="text-neutral-800 text-base font-medium">
              Generate CSV
            </span>
          </button>
        </div>
        <div className="self-stretch w-[250px] h-28 p-6 bg-white flex-col justify-start items-start gap-2.5 flex">
          {collapsed ? (
            <div className="w-9 h-9 rounded-full">
              <img
                src={user?.profilePic || userImage}
                alt="profile-pic"
                className="w-full h-full rounded-full object-fit"
              />
            </div>
          ) : (
            <div className="self-stretch px-4 py-3 bg-white rounded-lg border border-gray-200 justify-between items-center gap-2 flex">
              <div className="justify-start items-center gap-2 inline-flex">
                <div className="w-9 h-9 rounded-full">
                  <img
                    src={user?.profilePic || userImage}
                    alt="profile-pic"
                    className="w-full h-full rounded-full object-fit"
                  />
                </div>
                <div className="flex-col gap-0.5 flex">
                  <span className="text-dark1 text-sm font-medium">Admin</span>
                  <span className="text-dark3 text-xs font-normal">admin</span>
                </div>
              </div>
              <button type="button" onClick={handleLogout}>
                <LogoutIcon className="w-6 h-6" />
              </button>
            </div>
          )}
        </div>
      </div>
      {showSignupModal && (
        <AdminSignup open={showSignupModal} onClose={closeSignupModal} />
      )}
    </div>
  );
}
