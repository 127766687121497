import React, { useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "../../../../../components/Modal";
import { ReactComponent as CalendarIcon } from "../../../../../assets/calendar.svg";

const isSelectedTime = (selectedDate: any, time: any) => {
  if (selectedDate) {
    const selectedDateMoment = moment(selectedDate);
    const date = moment(selectedDate);
    const timeSplit = time.split(":");
    const hours = parseInt(timeSplit[0]);
    const minutes = parseInt(timeSplit[1]);
    date.set({ hour: hours, minute: minutes });
    // can be small till 30 minutes
    // should be same or less till 30 mintues
    const diff = date.diff(selectedDateMoment, "minutes");
    if (diff >= 0 && diff < 30) {
      return true;
    }
  }
  return false;
};

export const DateTimeView = ({ onChange, selectedDate }: any) => {
  const handleTimeSelect = (time: {
    ampm: string;
    time: string;
    fullTime: string;
  }) => {
    // set time to selected date with am/pm count
    const timeSplit = time.fullTime.split(":");
    const hours = parseInt(timeSplit[0]);
    const minutes = parseInt(timeSplit[1]);
    const date = moment(selectedDate);
    date.set({ hour: hours, minute: minutes });
    onChange(new Date(date.format()));
  };

  const TimeSlots = () => {
    const times: { ampm: string; time: string; fullTime: string }[] = [];
    const now = moment();
    for (let i = 0; i < 24; i++) {
      const time = moment({ hour: i });
      const halfPast = moment({ hour: i, minute: 30 });
      const date = moment(selectedDate);
      if (date && date.isSame(now, "day")) {
        if (time.isAfter(now)) {
          times.push({
            time: time.format("hh:mm"),
            ampm: time.format("A"),
            fullTime: time.format("HH:mm"),
          });
        }
        if (halfPast.isAfter(now)) {
          times.push({
            time: halfPast.format("hh:mm"),
            ampm: halfPast.format("A"),
            fullTime: halfPast.format("HH:mm"),
          });
        }
      } else {
        times.push({
          time: time.format("hh:mm"),
          ampm: time.format("A"),
          fullTime: time.format("HH:mm"),
        });
        times.push({
          time: halfPast.format("hh:mm"),
          ampm: halfPast.format("A"),
          fullTime: halfPast.format("HH:mm"),
        });
      }
    }
    return (
      <div className="max-h-[300px] overflow-auto">
        {times.map((time, i) => (
          <button
            onClick={() => handleTimeSelect(time)}
            key={i}
            className={`${
              isSelectedTime(selectedDate, time.fullTime)
                ? "border-[#1F7BF4]"
                : "border-transparent"
            } px-4 py-3 gap-12 flex rounded cursor-pointer hover:border-[#1F7BF4] border box-border`}
          >
            <span>{time.time}</span>
            <span>{time.ampm}</span>
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="flex gap-8 flex-col md:flex-row md:h-[350px]">
      <DatePicker
        selected={new Date(selectedDate)}
        onChange={(date) => onChange(date)}
        inline
        minDate={new Date()}
        className="custom-calendar"
        dayClassName={(date) =>
          `!w-10 !h-10 !inline-flex !text-dark1 font-medium text-sm border-primary hover:!bg-white hover:border items-center justify-center `
        }
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
      />
      <div className="time-slots md:border-l border-solid border-lightGray px-4">
        <div className="text-dark1 text-base font-medium mt-3 mb-1 pl-4 leading-tight">
          Time
        </div>
        <TimeSlots />
      </div>
    </div>
  );
};

export function DateTimePicker({
  onChange,
  value,
}: {
  onChange: any;
  value: any;
}) {
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };
  
  return (
    <div>
      <button
        onClick={openModal}
        className="flex items-center border border-lightGray px-3 py-2 rounded gap-1 w-[220px]"
      >
        <CalendarIcon />
        <span className="text-sm">
          {value ? moment(value).format("DD MMM YYYY - HH:mm A") : "Now"}
        </span>
      </button>
      <Modal onClose={closeModal} open={open}>
        <DateTimeView onChange={onChange} selectedDate={moment(value).format()} />
      </Modal>
    </div>
  );
}
