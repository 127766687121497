import { ReactComponent as CheckIcon } from "../../../../../assets/check.svg";

export function PostImageWithSelector({
  post,
  selected,
  onSelect,
  onClick,
}: {
  post: any;
  selected: boolean;
  onSelect?: any;
  onClick: any;
}) {
  return (
    <button onClick={onClick} className="relative bg-black/50">
      <div className="w-[146px] h-[146px]">
        <img
          src={post.imageUrl}
          alt="post"
          className="w-full h-full object-cover"
        />
      </div>
      {onSelect && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onSelect(post);
          }}
          className="absolute top-2 left-2"
        >
          {selected ? (
            <div className="rounded-full w-6 h-6 flex items-center justify-center bg-primary">
              <CheckIcon className="w-5 h-5 [&>path]:stroke-white" />
            </div>
          ) : (
            <div className="w-5 h-5 border border-2 rounded-full border-white" />
          )}
        </button>
      )}
    </button>
  );
}
