import { Button } from "../../../../../components/Button";
import { SelectInput } from "../../../../../components/SelectInput";

export function PostGeneratorStep1({ onChange, onNext, data }) {
  return (
    <div className="flex flex-col">
      <div className="text-neutral-800 text-2xl font-semibold mb-3 mt-8">
        I'd love to help you draft a fun new social media post
      </div>
      <div className="text-zinc-700 text-sm font-normal mb-8">
        Simply choose the type of post you’d like to create together. If you’d
        like to highlight a specific topic, be sure to mention it in your answer
        so I can create what you have in mind! Ready to begin?
      </div>
      <div className="w-fit">
        <SelectInput
          data={[
            { label: "Call-to-Action (CTA)", value: "CALL_TO_ACTION" },
            { label: "Informational", value: "INFORMATIONAL" },
            { label: "Testimonials", value: "TESTIMONIALS" },
            { label: "Personal Post", value: "PERSONAL_POST" },
            { label: "Share your Story", value: "SHARE_YOUR_STORY" },
          ]}
          value={data.type}
          onChange={(e: any) => {
            onChange({ ...data, type: e.target.value });
            // handleRegenerate({...postForm, type: e.target.value})
          }}
          className="!py-2 !bg-white !rounded w-fit"
        />
      </div>
      <div className="absolute bottom-0 left-0 right-6 lg:right-0 flex justify-end">
        <Button variant="primary" disabled={!data.type} onClick={onNext}>Next</Button>
      </div>
    </div>
  );
}
