import { Layout } from "./components/Layout";
import { Button } from "../../components/Button";
import { TextInput } from "../../components/TextInput";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useMemo } from "react";
import { api } from "../../services/api";
import {
  checkPasswordStrength,
  getUser,
  setToken,
  setUser,
  validateEmail,
} from "../../services/utils";
import { Separator } from "./components/Separator";
import { Password } from "./components/Password";
import { ConfirmEmail } from "./ConfirmEmail";
import { ReactComponent as AlertIcon } from "../../assets/Alert.svg";
import { Modal } from "../../components/Modal";
import { ShowToast } from "../../services/toast";

export function AdminSignup({ open, onClose }) {
  const navigate = useNavigate();
  const [showOnboarding, setShowOnboarding] = React.useState(false);
  const [showConfirmView, setShowConfirmView] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [isPasswordSame, setIsPasswordSame] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [emailError, setEmailError] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // get the form data
    const formData = new FormData(e.currentTarget);
    // convert the form data to an object
    const data = Object.fromEntries(formData);
    if (data.password !== data.confirmPassword) {
      setIsPasswordSame("Confirm password is not the same");
      return;
    }
    setIsPasswordSame("");
    // log the results
    console.log(data);
    try {
      setLoading(true);
      const response = await api.adminSignup({
        email: data.email,
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
      });
      console.log(response);
      setLoading(false);
      if (response.accessToken) {
        ShowToast({
          message: "Admin account created successfully",
          type: "success",
        });
        onClose();
      }
    } catch (error: any) {
      console.log(error);
      ShowToast({
        message: "Error creating admin account",
        type: "error",
      });
      setLoading(false);
    }
  };

  const handleValidateEmail = (e: any) => {
    const value = e.target.value;
    const isValidEmail = validateEmail(value);

    if (!isValidEmail) {
      setEmailError("Please enter a valid email");
    } else {
      setEmailError("");
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    console.log(data, formData.get("termCondition"));
    setPassword(data.password.toString());

    const isValidEmail = validateEmail(data?.email?.toString());

    if (isValidEmail) {
      setEmailError("");
    }

    if (
      data.email &&
      data.password &&
      data.confirmPassword &&
      data.firstName &&
      data.lastName &&
      data.password.toString().length >= 8 &&
      data.confirmPassword.toString().length >= 8 &&
      isValidEmail
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleOnConfirmEmail = () => {
    setShowConfirmView(false);
    // setShowOnboarding(true);
    navigate("/onboarding");
  };

  const handleSocialLoginSuccess = (data: any) => {
    // setShowOnboarding(true);
    navigate("/onboarding");
  };

  const { score, message } = useMemo(() => {
    return checkPasswordStrength(password);
  }, [password]);
  console.log(score, message);

  useEffect(() => {
    if (open) {
      setError("");
    }
  }, []);

  return (
    <Modal open={open} onClose={onClose}>
      <div className="flex flex-col w-full max-h-[calc(100vh_-_100px)] overflow-auto">
        <div className="rounded-lg p-6 bg-white flex flex-col mx-3">
          <div className="text-dark1 text-2xl font-semibold mb-2">
            Create admin account
          </div>

          <form
            onSubmit={handleSubmit}
            className="flex-1"
            onChange={handleChange}
          >
            {error && (
              <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                <AlertIcon /> {error}
              </div>
            )}
            <div className="my-6 mt-5 flex flex-col gap-5">
              <div className="flex items-center gap-4">
                <div className="flex flex-col gap-1.5 flex-1">
                  <span className="text-black text-xs font-medium">
                    First Name
                  </span>
                  <TextInput
                    placeholder="First name"
                    className="w-full !p-[14px]"
                    name="firstName"
                    onBlur={handleValidateEmail}
                  />
                </div>
                <div className="flex flex-col gap-1.5 flex-1">
                  <span className="text-black text-xs font-medium">
                    Last Name
                  </span>
                  <TextInput
                    placeholder="Last name"
                    className="w-full !p-[14px]"
                    name="lastName"
                    onBlur={handleValidateEmail}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-black text-xs font-medium">Email</span>
                <TextInput
                  placeholder="Your email"
                  className="w-full !p-[14px]"
                  name="email"
                  onBlur={handleValidateEmail}
                />
                {emailError && (
                  <span className="text-red-500 text-xs font-medium">
                    {emailError}
                  </span>
                )}
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-black text-xs font-medium">Password</span>
                <Password
                  placeholder="Your password"
                  className="!p-[14px]"
                  name="password"
                  // showCalculation
                  // score={score}
                  message={message}
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-black text-xs font-medium">
                  Confirm Password
                </span>
                <Password
                  type="password"
                  placeholder="Your confirmed password"
                  className="!p-[14px]"
                  name="confirmPassword"
                />
                {isPasswordSame && (
                  <span className="text-red-500 text-xs font-medium">
                    {isPasswordSame}
                  </span>
                )}
              </div>
            </div>
            <Button
              disabled={disabled}
              loading={loading}
              className="w-full"
              variant="primary"
            >
              Create
            </Button>
          </form>
        </div>
      </div>
    </Modal>
  );
}
