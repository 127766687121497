import React from "react";
import { BrandStyleTab } from "./BrandStyleTab";
import { Modal } from "../../../components/Modal";
import { Button } from "../../../components/Button";

function ColorInput({
  value,
  onChange,
}: {
  value: string;
  onChange: (e: any) => void;
}) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  return (
    <div className="flex gap-3 items-center flex-1 md:flex-none ">
      <button className="w-full md:w-48 h-20 relative z-[1]"
        onClick={() => {
          inputRef.current?.click();

        }
        }
      >
        <div
          className="w-full md:w-48 h-20 left-0 top-0 absolute rounded-lg"
          style={{ backgroundColor: value }}
        />
        <div className="left-[14px] top-[39px] absolute text-white text-lg font-semibold font-['Inter'] leading-normal">
          {value}
        </div>
      </button>
      <input
        type="color"
        className="absolute opacity-0"
        value={value}
        ref={inputRef}
        onChange={onChange}
      />
    </div>
  );
}

export function BrandStylesColor({
  colors,
  onUpdate,
}: {
  colors: string[];
  onUpdate: (data: any) => any;
}) {
  const [edit, setEdit] = React.useState<boolean>(false);
  const [editColors, setEditColors] = React.useState<string[]>([]);
  const [saving, setSaving] = React.useState<boolean>(false);

  const handleEdit = () => {
    setEdit(true);
    setEditColors(colors);
  };

  const handleClose = () => {
    setEdit(false);
  };
  
  const handleUpdate = async () => {
    setSaving(true);
    const response = await onUpdate({
      brandStyleColors: editColors,
    });
    setSaving(false);
    if (response) {
      setEdit(false);
    }
  };

  return (
    <div>
      <BrandStyleTab label="Colors" onEdit={handleEdit} />
      <div className="flex gap-3 flex-wrap mt-4">
        {(colors || []).map((color) => (
          <div className="w-52 h-20 relative" key={color}>
            <div
              className="w-52 h-20 left-0 top-0 absolute rounded-lg"
              style={{ backgroundColor: color }}
            />
            <div className="left-[14px] top-[39px] absolute text-white text-lg font-semibold font-['Inter'] leading-normal">
              {color}
            </div>
          </div>
        ))}
      </div>
      <Modal
        contentClassName="max-w-[525px] !mx-2"
        open={edit}
        onClose={handleClose}
      >
        <div>
          <div className="text-neutral-800 text-2xl font-semibold">
            Edit Colors
          </div>
          <div className="flex gap-4 flex-wrap mt-4 flex-col md:flex-row">
            {editColors.map((color, index) => (
              
                <ColorInput
                  value={color}
                  onChange={(e) => {
                    const newColors = [...editColors];
                    newColors[index] = e.target.value;
                    setEditColors(newColors);
                  }}
                  key={index}
                />
            ))}
          </div>
          <div className="mt-8 flex justify-end">
            <Button variant="primary" onClick={handleUpdate} loading={saving}>
              Update
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
