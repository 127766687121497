import { Link } from "react-router-dom";
import { ProfileTab } from "./components/ProfileTab";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { useEffect, useState } from "react";
import { Profile } from "./Profile";
import { BrandStyleGuide } from "./BrandStyleGuide";
import { getUser } from "../../services/utils";
import { api } from "../../services/api";
import { Button } from "../../components/Button";
import React from "react";

export function ProfileWrapper({ userId, userProfile }) {
  const [showOnBoarding, setShowOnBoarding] = useState<boolean>(false);
  const [profile, setProfile] = useState<any>({});
  const [fetching, setFetching] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("profile");
  const [sectionData, setSectionData] = useState<any>({});

  const fetchProfile = React.useCallback(() => {
    setFetching(true);
    api.getUserProfile(userId).then((response) => {
      setProfile(response);
      setFetching(false);
    });
  }, [userId]);

  useEffect(() => {
    // call api to get profile data, get id from localstorage
    fetchProfile();
  }, [fetchProfile]);

  useEffect(() => {
    api.getOnboardingSections().then((res) => {
      setSectionData(res);
    });
  }, []);

  return (
    <div>
      {/* <div className="flex justify-center gap-3.5 md:gap-0 md:justify-between items-center mb-4 flex-wrap">
        <ProfileTab activeTab={activeTab} onTabChange={setActiveTab} />
        {activeTab === "profile" && (
          <Button
            variant="outline"
            className="w-full md:w-auto px-5 py-3 bg-white rounded-md border border-primary justify-center items-center gap-1.5 flex"
            onClick={() => setShowOnBoarding(true)}
          >
            <EditIcon />
            <div className="text-black text-sm font-medium leading-normal">
              Edit profile
            </div>
          </Button>
        )}
      </div> */}
      {activeTab === "profile" && (
        <Profile
          profile={profile}
          fetching={fetching}
          setProfile={setProfile}
        />
      )}
      {/* {activeTab === "style-guide" && (
        <BrandStyleGuide profile={profile} userId={userId} />
      )} */}
    </div>
  );
}
