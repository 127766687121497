// export const GOOGLE_OAUTH_CLIENT_ID = "732782282804-m0v81v0sni9mhqorp5p7av2sqketkul0.apps.googleusercontent.com";
export const GOOGLE_OAUTH_CLIENT_ID =
  "876239620868-5veau2i9mtr4ofl5h04sai9hpc2nvine.apps.googleusercontent.com";
export const FACEBOOK_APP_ID = "979844743083952";
export const FACEBOOK_APP_SECRET = "772dbba055079542c031acbfb8cdbddc";
export const CONFIG_ID = "1046102856713967";

// dummy
// export const FACEBOOK_APP_ID = "1556404778514241";

export const scheduleTypes = [
  {
    name: "TESTIMONIALS",
    label: "Testimonials",
    colorClass: "bg-[#EE826B]",
  },
  {
    name: "INFORMATIONAL",
    label: "Informational",
    colorClass: "bg-[#688692]",
  },
  {
    name: "CALL_TO_ACTION",
    label: "Call-to-Action (CTA)",
    colorClass: "bg-[#B7D7E4]",
  },
  {
    name: "PERSONAL_POST",
    label: "Personal Post",
    colorClass: "bg-[#C1AD3F]",
  },
  {
    name: "SHARE_YOUR_STORY",
    label: "Share your Story",
    colorClass: "bg-[#E4C270]",
  },
];

export const keywordList = [
  "Energetic",
  "Uplifting",
  "Simple",
  "Serene",
  "Intutive",
  "Dynamic",
  "Radiant",
  "Grounded",
  "Inspiring",
  "Passionate",
  "Wholesome",
  "Nurturing",
  "Bold",
  "Visionary",
  "Empathetic",
  "Joyful",
  "Refreshing",
  "Balanced",
  "Harmonious",
  "Sexy",
  "Magnetic",
  "Luminous",
  "Deep",
  "Evocative",
  "Empowering",
  "Tranquil",
  "Spirited",
  "Motivational",
];

export const chartFilters = {
  CURRENT_WEEK: "CURRENT_WEEK",
  CURRENT_MONTH: "CURRENT_MONTH",
  CURRENT_YEAR: "CURRENT_YEAR",
};

export const chartFiltersLabel = {
  CURRENT_WEEK: "This Week",
  CURRENT_MONTH: "This Month",
  CURRENT_YEAR: "This Year",
};
