import { useEffect, useState } from "react";
import { Breadcrumb } from "./components/Breadcrumb";
import { UserInfoCard } from "./components/UserInfoCard";
import { Dashboard } from "./dashboard/Dashboard";
import { Schedule } from "./schedule";
import { MediaLibrary } from "./media-library/MediaLibrary";
import { BrandStyleGuide } from "../../profile/BrandStyleGuide";
import { ProfileWrapper } from "../../profile/ProfileWrapper";
import { Progress } from "./progress/Progress";
import React from "react";
import { api } from "../../../services/api";
import { DeactivateConfirm } from "../List/components/DeactivateConfirm";
import { LoadingScreen } from "../../profile/LoadingScreen";
import { useParams, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { EditProfileModal } from "../List/components/EditProfile";

const tabs = [
  { name: "dashboard", label: "Dashboard" },
  { name: "progress", label: "Progress" },
  { name: "schedule", label: "Schedule" },
  { name: "media-library", label: "Media Library" },
  { name: "profile", label: "Profile" },
  { name: "brand-kit", label: "Brand Kit" },
];

export function View() {
  const params = useParams();
  const { id: userId } = params;
  const [selectedTab, setSelectedTab] = useState(tabs[0].name);
  const [profile, setProfile] = useState<any>({});
  const [dashboard, setDashboard] = useState<any>({});

  const [deactivating, setDeactivating] = useState(false);
  const [removing, setRemoving] = useState(false);

  const [deactiveConfirm, setDeactiveConfirm] = useState<boolean>(false);
  const [removeConfirm, setRemoveConfirm] = useState<boolean>(false);

  const [openEditForm, setEditOpenForm] = useState<any>();
  const [fetching, setFetching] = useState<boolean>(false);

  const fetchProfile = React.useCallback(() => {
    if (!userId) return;
    setFetching(true);
    api.getUserProfile(userId).then((response) => {
      setProfile(response);
      setFetching(false);
    });
  }, [userId]);

  const handleDeactivate = () => {
    setDeactiveConfirm(true);
  };

  const fetchDashboard = async (userId: string) => {
    try {
      const res = await api.getDashboard(userId);
      setDashboard(res);
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeactivateConfirm = () => {
    if (!userId) return;
    setDeactivating(true);
    api
      .deleteUser(userId)
      .then((response) => {
        console.log(response);
        toast.success("User deactivated successfully");
        window.location.href = "/users";
      })
      .catch((err) => {
        toast.error("Something went wrong");
        console.log(err);
      })
      .finally(() => {
        setDeactivating(false);
      });
  };

  const handleRemove = () => {
    setRemoveConfirm(true);
  };

  const handleRemoveConfirm = () => {
    if (!userId) return;
    setRemoving(true);
    api
      .deleteUser(userId)
      .then((response) => {
        console.log(response);
        toast.success("User removed successfully");
        window.location.href = "/users";
      })
      .catch((err) => {
        toast.error("Something went wrong");
        console.log(err);
      })
      .finally(() => {
        setRemoving(false);
      });
  };

  const handleEdit = () => {
    setEditOpenForm(true);
  };

  const closeEditForm = () => {
    setEditOpenForm(false);
  };

  useEffect(() => {
    // call api to get profile data, get id from localstorage
    fetchProfile();
  }, [fetchProfile]);

  useEffect(() => {
    if (userId) {
      fetchDashboard(userId);
    }
  }, [userId]);

  if (fetching) {
    return (
      <div className="relative bg-ultraLightGray p-4 md:p-6">
        <LoadingScreen />
      </div>
    );
  }
  console.log(userId);
  if (!userId) {
    return null;
  }
  return (
    <div className="relative bg-ultraLightGray min-h-[100vh]">
      <div className="max-w-[1440px] mx-auto p-4 md:p-6">
        <div className="mb-6">
          <Breadcrumb
            name={`${profile?.onboardingSubmissions?.FIRST_NAME || ""} ${
              profile?.onboardingSubmissions?.LAST_NAME || ""
            }`}
          />
        </div>
        <UserInfoCard
          profile={{ ...profile, ...(dashboard?.profile || {}) }}
          onDeactivate={handleDeactivate}
          onEdit={handleEdit}
          onRemove={handleRemove}
        />
        <div className="flex p-1 border border-lightGray rounded-md w-fit mt-6">
          {tabs.map((tab) => (
            <button
              onClick={() => setSelectedTab(tab.name)}
              key={tab.name}
              className={`rounded px-4 py-3 ${
                selectedTab === tab.name ? "bg-white" : ""
              }`}
            >
              {tab.label}
            </button>
          ))}
        </div>
        <div className="mt-6">
          {selectedTab === "dashboard" && (
            <Dashboard userId={userId} userProfile={profile} />
          )}
          {selectedTab === "progress" && (
            <Progress userId={userId} userProfile={profile} />
          )}
          {selectedTab === "schedule" && (
            <Schedule userId={userId} userProfile={profile} />
          )}
          {selectedTab === "media-library" && (
            <MediaLibrary userId={userId} userProfile={profile} />
          )}
          {selectedTab === "profile" && (
            <ProfileWrapper userId={userId} userProfile={profile} />
          )}
          {selectedTab === "brand-kit" && (
            <BrandStyleGuide userId={userId} userProfile={profile} />
          )}
        </div>
      </div>
      <DeactivateConfirm
        open={deactiveConfirm}
        name={profile?.onboardingSubmissions?.FIRST_NAME}
        title={`Are you sure you want to deactivate account of ${profile?.onboardingSubmissions?.FIRST_NAME} ${profile?.onboardingSubmissions?.LAST_NAME}?`}
        description="User will not be able to access anything on this portal after deactivating."
        closeButtonText="Go back"
        confirmButtonText="Yes, deactivate"
        onClose={() => setDeactiveConfirm(false)}
        onConfirm={handleDeactivateConfirm}
        loading={deactivating}
      />
      <DeactivateConfirm
        open={removeConfirm}
        onClose={() => setRemoveConfirm(false)}
        onConfirm={handleRemoveConfirm}
        name={profile?.onboardingSubmissions?.FIRST_NAME}
        title={`Are you sure you want to remove the account of ${profile?.onboardingSubmissions?.FIRST_NAME} ${profile?.onboardingSubmissions?.LAST_NAME}?`}
        description="All the data of this user will be deleted permanently from this portal."
        closeButtonText="Go back"
        confirmButtonText="Yes, Remove"
        loading={removing}
      />
      {openEditForm && (
        <EditProfileModal
          user={profile}
          open={openEditForm}
          onClose={closeEditForm}
          refetch={fetchProfile}
          userId={userId}
        />
      )}
    </div>
  );
}
