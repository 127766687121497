import { ReactComponent as EditIcon } from "../../../assets/pencil.svg";

export function BrandStyleTab({
  onEdit,
  label,
}: {
  onEdit?: () => void;
  label: string;
}) {
  return (
    <div className="flex justify-between items-center">
      <h2 className="font-semibold text-2xl text-dark1">{label}</h2>
      {/* {onEdit && <button onClick={onEdit}>
        <EditIcon />
      </button>} */}
    </div>
  );
}
