import React, { useEffect } from "react";
import { Modal } from "../../../components/Modal";
import { BrandStyleTab } from "./BrandStyleTab";
import { Button } from "../../../components/Button";
import { BrandingStyleConfirm } from "./BrandingStyleConfirm";
import { keywordList } from "../../../constant";

export function BrandStylesBrandValues({
  brandValues,
  onUpdate,
}: {
  brandValues: string[];
  onUpdate: (data: any) => any;
}) {
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [edit, setEdit] = React.useState<boolean>(false);

  const toggleSelected = (item: string) => {
    if (selected.includes(item)) {
      setSelected(selected.filter((i) => i !== item));
    } else {
      if (selected.length < 5) {
        setSelected([...selected, item]);
      }
    }
  };

  const handleUpdate = async () => {
    setShowConfirm(true);
  };

  const handleUpdateConfirm = async() => {
    setShowConfirm(false);
    setSaving(true);
    const response = await onUpdate(selected);
    setSaving(false);
    if (response) {
      setEdit(false);
    }
  }

  useEffect(() => {
    if (brandValues) {
      setSelected(brandValues);
    }
  }, [brandValues]);

  return (
    <div>
      <BrandStyleTab label="Brand Values" onEdit={() => setEdit(true)} />
      <div className="flex flex-wrap gap-2 mt-4">
        {brandValues?.map((keyword: string) => (
          <div className="px-3.5 py-2 flex items-center justify-center bg-white rounded border border-gray-200">
            <div className="text-neutral-800 text-sm font-normal">
              {keyword}
            </div>
          </div>
        ))}
      </div>
      <Modal
        contentClassName="!max-w-[525px] !mx-2"
        open={edit}
        onClose={() => setEdit(false)}
      >
        <div>
          <div className="text-neutral-800 text-2xl font-semibold">
            Your brand is an extension of your essence
          </div>
          <div className="mb-9 mt-3 text-zinc-700 text-sm font-normal">
            To help us crystallize your brand identity, please select up to 5
            words that resonate with your coaching spirit:
          </div>
          <div className="flex-1 grid grid-cols-3 md:grid-cols-4 gap-2">
            {keywordList.map((item) => (
              <button
                onClick={() => toggleSelected(item)}
                className={`w-28 text-center px-3.5 py-2 bg-white rounded border ${
                  selected.includes(item)
                    ? "border-primary"
                    : "border-lightGray"
                }`}
                disabled={loading}
              >
                <div
                  className={`${
                    selected.includes(item) ? "text-black" : "text-grey1"
                  } text-sm font-medium leading-normal`}
                >
                  {item}
                </div>
              </button>
            ))}
          </div>
          <div className="mt-8 flex justify-end">
            <Button variant="primary" onClick={handleUpdate} loading={saving}>
              Update
            </Button>
          </div>
        </div>
      </Modal>
      <BrandingStyleConfirm 
        onClose={() => setShowConfirm(false)}
        open={showConfirm}
        onConfirm={handleUpdateConfirm}
      />
    </div>
  );
}
