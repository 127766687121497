import { Button } from "../../../../components/Button";
import { Modal } from "../../../../components/Modal";
import { TextInput } from "../../../../components/TextInput";
import { ReactComponent as CheckIcon } from "../../../../assets/check.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/x.svg";
import { Loading } from "../../../../components/Loading/Loading";

export function DeactivateConfirm({
  open,
  onClose,
  onConfirm,
  name,
  title,
  description,
  closeButtonText,
  confirmButtonText,
  loading,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading?: boolean;
  name: string;
  title: string;
  description: string;
  closeButtonText: string;
  confirmButtonText: string;
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      showCloseButton={false}
      contentClassName="!p-8 max-w-[532px]"
    >
      <div className="mb-6 flex items-start justify-between">
        <h1 className="text-dark1 text-[24px] font-semibold">{title}</h1>
        <button onClick={onClose} aria-label="Close" className="pt-2">
          <CloseIcon className="w-6 h-6 [&_path]:stroke-black" />
        </button>
      </div>
      <div className="text-base">{description}</div>
      <div className="flex justify-end items-center gap-2.5 mt-6">
        <Button variant="outline" onClick={onClose}>
          {closeButtonText}
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          <span>{confirmButtonText}</span>
          {loading && <Loading />}
        </Button>
      </div>
    </Modal>
  );
}
