import { Layout } from "./components/Layout";
import { Button } from "../../components/Button";
import { TextInput } from "../../components/TextInput";
import { Link } from "react-router-dom";
import React from "react";
import { api } from "../../services/api";
import { setToken, setUser } from "../../services/utils";
import { ReactComponent as Email } from "../../assets/email.svg";
import { ReactComponent as Back } from "../../assets/back.svg";
import { ReactComponent as Lock } from "../../assets/lock.svg";
import { ReactComponent as AmericanExpress } from "../../assets/AmericanExpress.svg";
import { ReactComponent as Visa } from "../../assets/Visa.svg";
import { ReactComponent as PayPal } from "../../assets/PayPal.svg";
import { ReactComponent as MasterCard } from "../../assets/Mastercard.svg";
import { ReactComponent as ChevronRight } from "../../assets/right-chevron.svg";

import OtpInput from "react-otp-input";

export function Payment() {
  const [showOnboarding, setShowOnboarding] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [isPasswordSame, setIsPasswordSame] = React.useState<string>("");
  const [otp, setOtp] = React.useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // get the form data
    const formData = new FormData(e.currentTarget);
    // convert the form data to an object
    const data = Object.fromEntries(formData);
    // log the results
    console.log(data);
    setLoading(true);
    const response = await api.signup({
      email: data.email,
      password: data.password,
    });
    console.log(response);
    if (response.accessToken) {
      setToken(response.accessToken);
      setUser({ ...response });
      setShowOnboarding(true);
    }
    setLoading(false);
  };
  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    console.log(data);
    if (data.email && data.password && data.confirmPassword) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  return (
    <Layout>
      <div className="flex flex-col w-full md:w-[438px] max-h-[calc(100vh_-_100px)] overflow-auto">
        <div className="relative rounded-lg p-6 bg-white flex flex-col items-center mx-3">
          <div className="p-6 rounded-full w-fit border border-primary flex">
            <Lock className="w-12 h-12" />
          </div>
          <div className="mb-7 text-dark1 text-2xl font-semibold leading-loose">
            Set up your payment
          </div>
          <p className="text-center text-dark2 text-sm font-normal leading-normal ">
            Your membership starts as soon as you set up payment
          </p>
          <div className="mt-7 text-center text-neutral-800 text-sm font-medium font-['Inter']">
            No commitments. <br />
            Cancel online any time.
          </div>
          <div className="w-full flex flex-col gap-4 mt-8">
            <div className="flex items-center justify-between p-3 w-full rounded border border-gray-200">
              <div className="flex items-center gap-4">
                <div className="text-zinc-700 text-sm font-normal font-['Inter']">
                  Credit or Debit Card
                </div>
                <div className="flex gap-2 items-center">
                  <MasterCard />
                  <Visa />
                  <AmericanExpress />
                </div>
              </div>
              <ChevronRight />
            </div>
            <div className="flex items-center justify-between p-3 w-full rounded border border-gray-200">
              <div className="flex items-center gap-4">
                <div className="text-zinc-700 text-sm font-normal font-['Inter']">
                  Paypal
                </div>
                <div className="flex gap-2 items-center">
                  <PayPal />
                </div>
              </div>
              <ChevronRight />
            </div>
            <div className="flex items-center justify-between p-3 w-full rounded border border-gray-200">
              <div className="flex items-center gap-4">
                <div className="text-zinc-700 text-sm font-normal font-['Inter']">
                  Gift code
                </div>
              </div>
              <ChevronRight />
            </div>
          </div>
          <div className="mt-12 flex gap-2">
            <Lock className="w-4 h-4" />
            <div className="text-dark2 text-sm font-normal leading-normal ">
              Secure Server
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
