import * as React from "react";
import { ReactComponent as CloseIcon } from "../../assets/x.svg";

/**Type of Input-Props */
export type Props = {
  /**Emits index */
  onRemove: Function;
  /**Array of chips */
  chips: string[];
  /**Emits value */
  onSubmit: Function;
};

export function ChipInput({ onRemove, chips, onSubmit }: Props) {
  const formControlRef = React.useRef<any>();
  /**Called when user clicks on remove icon.
   * And in turn, onRemove of props called, wih index passed as argument.
   */
  const removeChip = (index: number) => {
    onRemove(index);
  };

  return (
    /**The main container div */
    <div className={`flex items-center`}>
      <div className="flex flex-wrap items-center gap-1">
        {/* Each chip is bootstrap's col */}
        {chips.map((chip, index) => (
            <div key={index} className="flex items-center bg-white rounded-full border border-primary text-primary px-3 py-1 text-sm font-semibold gap-1">
              {chip}
              <button onClick={() => removeChip(index)}>
                <CloseIcon className={"w-4 h-4 [&>path]:stroke-black"} />
              </button>
            </div>
        ))}
        <div>
          {/* The input, from which value is read and chip is added accordingly */}
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              onSubmit(formControlRef?.current?.value);
              formControlRef.current.value = "";
            }}
            noValidate
          >
            <input
              ref={formControlRef}
              name="chipInput"
              placeholder="Tag People"
              aria-label="Chip Input"
              className="text-sm w-full border-none !outline-none"
            />
          </form>
        </div>
      </div>
    </div>
  );
}
