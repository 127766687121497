import { LoadingScreen } from "./LoadingScreen";
import { useEffect, useState } from "react";
import { BrandStylesColor } from "./components/BrandStylesColor";
import { BrandStylesFont } from "./components/BrandStylesFont";
import { BrandStylesBrandValues } from "./components/BrandStylesBrandValues";
import { api } from "../../services/api";
import React from "react";
import { ShowToast } from "../../services/toast";

export function BrandStyleGuide({
  userId,
  userProfile,
}: {
  userProfile?: any;
  userId: string;
}) {
  const [brandStyleInfo, setBrandStyleInfo] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const fetchBrandStyleInfo = React.useCallback(() => {
    setLoading(true);
    api
      .getBrandStyles(userId)
      .then((response) => {
        console.log(response);
        setBrandStyleInfo(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userId]);

  const handleBrandValueSave = async (data: any) => {
    // const response = await api.saveBrandStyles(userId, data);
    // fetchBrandStyleInfo();
    // return response;

    if (data.length > 0) {
      const response = await api.saveOnboardingSection("KEYWORDS", {
        answers: {
          KEYWORDS: data,
        },
      });
      setLoading(false);
      console.log(response);
      if (response.status === 200) {
        if (userId) {
          const response = await api.generatePrompt(userId);
          console.log(response);
        }
        await fetchBrandStyleInfo();
        ShowToast({ message: "Keywords saved successfully", type: "success" });
        return true;
      }
    }
    return false;
  };

  const handleBrandStyleSave = async (data: any) => {
    console.log(data);
    const response = await api.saveBrandStyles(userId, data);
    if (response.status === 200) {
      await fetchBrandStyleInfo();
      ShowToast({ message: "Brand style saved successfully", type: "success" });
      return true;
    }
    return false;
  };

  useEffect(() => {
    fetchBrandStyleInfo();
  }, [fetchBrandStyleInfo]);
  console.log(brandStyleInfo);

  if (loading) {
    return (
      <div className="relative bg-ultraLightGray p-4 md:p-6">
        <LoadingScreen />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-[60px]">
      <BrandStylesColor
        colors={brandStyleInfo.colors}
        onUpdate={handleBrandStyleSave}
      />
      <BrandStylesFont
        fonts={brandStyleInfo.typography}
        onUpdate={handleBrandStyleSave}
      />
      <BrandStylesBrandValues
        brandValues={brandStyleInfo.brandValues}
        onUpdate={handleBrandValueSave}
      />
      {loading && <LoadingScreen />}
    </div>
  );
}
