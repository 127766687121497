import { Button } from "../../../../components/Button";
import { Modal } from "../../../../components/Modal";
import { TextInput } from "../../../../components/TextInput";
import { ReactComponent as CheckIcon } from "../../../../assets/check.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/x.svg";
import { useEffect, useMemo, useState } from "react";
import { DatePicker } from "../../../../components/DatePicker/DatePicker";

export function Filters({
  open,
  onClose,
  onFilter,
  selectedFilters,
  onReset,
}: {
  open: boolean;
  onClose: () => void;
  onFilter: (filters) => void;
  selectedFilters: any;
  onReset: () => void;
}) {
  const [filters, setFilters] = useState<any>({
    startDate: "",
    endDate: "",
    tier: [],
    planTypes: [],
  });
  const handleApply = () => {
    onFilter(filters);
    onClose();
  };

  const handleTierChange = (e) => {
    // plan type is checkbox so multiple values can be selected
    // so we need to store the values in an array
    // and toggle the values in the array based on the checkbox
    // checked state
    const tier = e.target.value;
    const tierArray = filters.tier || [];
    if (e.target.checked) {
      tierArray.push(tier);
    } else {
      const index = tierArray.indexOf(tier);
      tierArray.splice(index, 1);
    }
    setFilters((prev) => ({ ...prev, tier: tierArray }));
  };

  const handlePlanTypeChange = (e) => {
    // plan type is checkbox so multiple values can be selected
    // so we need to store the values in an array
    // and toggle the values in the array based on the checkbox
    // checked state
    const planType = e.target.value;
    const planTypeArray = filters.planTypes || [];
    if (e.target.checked) {
      planTypeArray.push(planType);
    } else {
      const index = planTypeArray.indexOf(planType);
      planTypeArray.splice(index, 1);
    }
    setFilters((prev) => ({ ...prev, planTypes: planTypeArray }));
  };

  useEffect(() => {
    setFilters(selectedFilters);
  }, [selectedFilters]);

  const dateError = useMemo(() => {
    if (filters.startDate && filters.endDate) {
      if (filters.startDate > filters.endDate) {
        return "Start date should be less than end date";
      }
    }
    return "";
  }, [filters.startDate, filters.endDate]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      showCloseButton={false}
      contentClassName="!p-8"
    >
      <div className="mb-6 flex items-center justify-between">
        <h1 className="text-dark1 text-[24px] font-semibold">Filters</h1>
        <button onClick={onClose} aria-label="Close">
          <CloseIcon className="w-6 h-6 [&_path]:stroke-black" />
        </button>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <label className="text-base">By Joining Date</label>
          <div className="flex items-center gap-2 flex-1">
            <div className="flex-1">
              <DatePicker
                value={filters.startDate}
                onChange={(value) =>
                  setFilters((prev) => ({ ...prev, startDate: value }))
                }
              />
            </div>
            <span>To</span>
            <div className="flex-1">
              <DatePicker
                value={filters.endDate}
                onChange={(value) =>
                  setFilters((prev) => ({ ...prev, endDate: value }))
                }
              />
            </div>
          </div>
          {dateError && (
            <span className="text-red-500 text-xs font-medium">
              {dateError}
            </span>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-base">Plan type</label>
          <div className="flex items-center rounded-lg border border-[#D9D9E7] p-[18px] gap-[60px]">
            <div className="flex items-center gap-1.5">
              <input
                type="checkbox"
                className="h-[22px] w-[22px]"
                onChange={handleTierChange}
                value={"ST"}
                checked={filters.tier?.includes("ST")}
              />
              <label className="text-base">Standard</label>
            </div>
            <div className="flex items-center gap-1.5">
              <input
                type="checkbox"
                className="h-[22px] w-[22px]"
                onChange={handleTierChange}
                value="PL"
                checked={filters.tier?.includes("PL")}
              />
              <label>Plus</label>
            </div>
            <div className="flex items-center gap-1.5">
              <input
                type="checkbox"
                className="h-[22px] w-[22px]"
                onChange={handleTierChange}
                value="PR"
                checked={filters.tier?.includes("PR")}
              />
              <label>Premium</label>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-base">Subscription type</label>
          <div className="flex items-center rounded-lg border border-[#D9D9E7] p-[18px] gap-[60px]">
            <div className="flex items-center gap-1.5">
              <input
                type="checkbox"
                className="h-[22px] w-[22px]"
                onChange={handlePlanTypeChange}
                value={"Y"}
                checked={filters.planTypes?.includes("Y")}
              />
              <label className="text-base">Yearly</label>
            </div>
            <div className="flex items-center gap-1.5">
              <input
                type="checkbox"
                className="h-[22px] w-[22px]"
                onChange={handlePlanTypeChange}
                value="M"
                checked={filters.planTypes?.includes("M")}
              />
              <label>Monthly</label>
            </div>
          </div>
        </div>
        <div className="flex justify-end items-center gap-2.5">
          <Button variant="outline" onClick={onReset}>
            Reset All
          </Button>
          <Button
            variant="primary"
            disabled={!!dateError}
            onClick={handleApply}
          >
            <div className="flex items-center gap-1.5">
              <CheckIcon className="[&_path]:stroke-black w-4 h-4" />
              <span>Apply</span>
            </div>
          </Button>
        </div>
      </div>
    </Modal>
  );
}
